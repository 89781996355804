<style>
  #dv-right-program { position: absolute; right: -1100px; top: 0; width: 1000px; }
  #dv-right-program .bg { position: absolute; right: 0; top: 20px; width: 100%; }
  #dv-right-program .time { position: absolute; right: 70px; top: 140px; width: 400px; text-align: right; font-size: 18px; font-weight: 600; font-style: normal; }

  #dv-right-program .text{ position: absolute; left: 120px; top: 205px; font-size: 16px; font-weight: bold; width: 810px; }
  #dv-right-program .text span{ color: #00ffff; font-family: 'Work Sans', sans-serif, "microsoft yahei"; }/* 'Teko' */

  #p2 { position: absolute; left: -25px; top: 0; font-weight: bold; font-family: 'Work Sans', sans-serif, "microsoft yahei"; }
  #p2 span { color: #00ffff; }
  #p2 .bg01{ position: absolute; left: 130px; top: 200px; width: 100px; height: 36px; }
  #p2 .filter{ filter: grayscale(80%) }
  #p2 .label_trend{ position: absolute; left: 140px; top: 207px; width: 200px; font-size: 16px; font-weight: bold; }
  #p2 .chartTime{ position: absolute; left: 740px; top: 212px; font-size: 14px; font-weight: normal; width: 200px; text-align: right; }
  #p2 .bg02{ position: absolute; left: 130px; top: 250px; }
  #p2 .bg03{ position: absolute; left: 545px; top: 250px; }
  #p2 .bg04{ position: absolute; left: 130px; top: 455px; }
  #p2 .bg05{ position: absolute; left: 545px; top: 455px; }
  #p2 .bg02-title{ position: absolute; left: 135px; top: 255px; }
  #p2 .bg04-title{ position: absolute; left: 550px; top: 255px; }
  #p2 .bg05-title{ position: absolute; left: 135px; top: 460px; }
  #p2 .bg03-title{ position: absolute; left: 550px; top: 460px; }
  #p2 .label_trend_01{ position: absolute; left: 150px; top: 256px; width: 200px; }
  #p2 .label_trend_03{ position: absolute; left: 565px; top: 256px; width: 200px; }
  #p2 .label_trend_04{ position: absolute; left: 150px; top: 461px; width: 200px; }
  #p2 .label_trend_02{ position: absolute; left: 565px; top: 461px; width: 200px; }
  /* 项目数据列表 */
  #p2 .tunnelList {position: absolute; left: 0; top: 0; width: 1000px; }
  #p2 .tunnelList .bg-4{ position: absolute; left: 130px; top: 680px; width: 114px; height: 36px; }
  #p2 .tunnelList .bg-5{ position: absolute; left: 249px; top: 680px; width: 150px; height: 36px; }
  #p2 .tunnelList .bg-6{ position: absolute; left: 130px; top: 728px; width: 806px; height: 36px; }
  #p2 .tunnelList .bg-14{ position: absolute; left: 130px; top: 762px; }
  #p2 .tunnelList .bg-line{ position: absolute; left: -22px; top: 0; }
  
  #p2 .sign{ position: absolute; left: -22px; top: 0; background: url('/imgs/p101/sign02.png') no-repeat left top; width: 28px; height: 30px; background-size: 28px; cursor: pointer; }
  #p2 .sign.selected{ background-image: url('/imgs/p101/sign01.png') }

  #p2 .tunnelList .bgs .filter{ filter: grayscale(80%) }
  #p2 .tunnelList .pageBtns{ position: absolute; left: 845px; top: 689px; width: 80px; text-align: right; }
  #p2 .tunnelList .pageBtns .pagebtn{ margin: 0 3px }
  #p2 .tunnelList .labels { position: absolute; left: 130px; top: 206px; width: 850px; }
  #p2 .tunnelList .labels .item-3{ position: absolute; left: 15px; top: 479px; font-size: 17px; line-height: 170%; cursor: pointer; }
  #p2 .tunnelList .labels .item-4{ position: absolute; left: 134px; top: 479px; font-size: 17px; line-height: 170%; cursor: pointer; }	
  #p2 .tunnelList .labels .item-5{ position: absolute; left: 20px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p2 .tunnelList .labels .item-6{ position: absolute; left: 173px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p2 .tunnelList .labels .item-7{ position: absolute; left: 258px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p2 .tunnelList .labels .item-8{ position: absolute; left: 328px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p2 .tunnelList .labels .item-9{ position: absolute; left: 415px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p2 .tunnelList .labels .item-10{ position: absolute; left: 515px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p2 .tunnelList .labels .item-11{ position: absolute; left: 615px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p2 .tunnelList .labels .item-12{ position: absolute; left: 715px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p2 .tunnelList .ProgramList { position: absolute; left: 120px; top:770px; width: 820px; font-size: 15px; height: 150px; overflow: hidden; }
  #p2 .tunnelList .ProgramList .program { position: absolute; left: 30px; width: 800px; }
  #p2 .tunnelList .ProgramList .program span:nth-of-type(1){ position: absolute; left: 18px; top: 3px; color: #ffffff; cursor: pointer; }
  #p2 .tunnelList .ProgramList .program span:nth-of-type(2){ position: absolute; left: 150px; top: 3px; width: 65px; text-align: right; }
  #p2 .tunnelList .ProgramList .program span:nth-of-type(3){ position: absolute; left: 150px; top: 3px; width: 145px; text-align: right; }
  #p2 .tunnelList .ProgramList .program span:nth-of-type(4){ position: absolute; left: 310px; top: 3px; width: 60px; text-align: right; }
  #p2 .tunnelList .ProgramList .program .bar3d1{ position: absolute; left: 395px; top: 5px; width: 80px; }
  #p2 .tunnelList .ProgramList .program .bar3d2{ position: absolute; left: 495px; top: 5px; width: 80px; }
  #p2 .tunnelList .ProgramList .program .bar3d3{ position: absolute; left: 595px; top: 5px; width: 80px; }
  #p2 .tunnelList .ProgramList .program .bar3d4{ position: absolute; left: 695px; top: 5px; width: 80px; }
  #p2 .tunnelList .ProgramList .program .text span{ font-weight: normal; }
  
  #p2 .echarts #chart_p2_01{ position: absolute; left: 145px; top: 280px; width: 375px; height: 145px; /* border: 1px solid red; */ }
  #p2 .echarts #chart_p2_03{ position: absolute; left: 560px; top: 280px; width: 375px; height: 145px; /* border: 1px solid red; */ }
  #p2 .echarts #chart_p2_04{ position: absolute; left: 145px; top: 485px; width: 375px; height: 145px; /* border: 1px solid red; */ }
  #p2 .echarts #chart_p2_02{ position: absolute; left: 560px; top: 485px; width: 375px; height: 145px; /* border: 1px solid red; */ }

</style>

<template>
  <div id="dv-right-program">
    <dv-img class="bg" src="../imgs/img/right/right.json" type="svg" loop="true"></dv-img>
    <i class="time" key="trans-02" v-text="currentTime"></i>

    <div class="page" id="p2">
      <dv-img class="bg01" src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"></dv-img>
      <div class="label_trend">发展趋势图</div>
      <dv-img class="bg02" src="imgs/p3/0814aff0-a250-46c9-99d7-565b05d6ab84.png"></dv-img>
      <dv-img class="bg03" src="imgs/p3/0814aff0-a250-46c9-99d7-565b05d6ab84.png"></dv-img>
      <dv-img class="bg04" src="imgs/p3/0814aff0-a250-46c9-99d7-565b05d6ab84.png"></dv-img>
      <dv-img class="bg05" src="imgs/p3/0814aff0-a250-46c9-99d7-565b05d6ab84.png"></dv-img>
      <dv-img class="bg02-title" src="imgs/p3/f7522c50-0cbe-4384-b554-fdec0c1bfde5.png"></dv-img>
      <dv-img class="bg03-title" src="imgs/p3/6707e5e0-5929-4452-b4e5-684c4199da79.png"></dv-img>
      <dv-img class="bg04-title" src="imgs/p3/48ce1d6c-2b7e-427a-bf12-bf5ccf25d2b8.png"></dv-img>
      <dv-img class="bg05-title" src="imgs/p3/f09ce625-c05b-4e73-a318-a0b95a72b81f.png"></dv-img>
      <div class="label_trend_01">厚度不足问题发展趋势</div>
      <div class="label_trend_02">空洞、不密实问题发展趋势</div>
      <div class="label_trend_03">钢筋缺失问题发展趋势</div>
      <div class="label_trend_04">防裂钢筋网问题发展趋势</div>
      <div class="echarts">
        <div id="chart_p2_01"></div>
        <div id="chart_p2_02"></div>
        <div id="chart_p2_03"></div>
        <div id="chart_p2_04"></div>
      </div>
      <!-- 隧道数据列表 -->
      <div class="tunnelList">
        <!-- 隧道数据列表 -->
        <dv-img :class="'bg-4' + (contrastIndex == 1 ? '' : ' filter')" @click="toggleContrastBtn" data-index="1" src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"></dv-img>
        <dv-img :class="'bg-5' + (contrastIndex == 1 ? ' filter' : '')" @click="toggleContrastBtn" data-index="2" src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"></dv-img>
        <dv-img class="bg-6" src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"></dv-img>
        <dv-img class="bg-14" src="imgs/p2/line.png"></dv-img>
        <div class="labels">
          <div class="item-3" @click="toggleContrastBtn" data-index="1">缺陷率对比</div>
          <div class="item-4" @click="toggleContrastBtn" data-index="2">整改合格率对比</div>
          <div class="item-5">隧道名称</div>
          <div class="item-6">检测覆盖率</div>
          <div class="item-7" v-text="contrastIndex == 1 ? '总缺陷率' : '已整改率'"></div>
          <div class="item-8" v-text="contrastIndex == 1 ? '7天验证超时' : '7天验证超时'"></div>
          <div class="item-9">厚度不足</div>
          <div class="item-10">钢筋缺失</div>
          <div class="item-11">防裂钢筋网</div>
          <div class="item-12">空洞、不密实</div>
        </div> 
        <div class="pageBtns">
          <dv-img class="pagebtn" v-for="pageIndex in (Math.floor(getContrast.length / 5) + (getContrast.length % 5 > 0 ? 1 : 0))" :key="'page_' + pageIndex" :title="pageIndex" @mouseover="selectPage" :data-index="pageIndex" :src="'imgs/p2/' + (currentPage == pageIndex ? '06f15087-c767-45be-977e-2e00800cfac6' : '2dc0cea5-876d-4e53-9c26-10debca2d21b') + '.png'"></dv-img>
        </div>

        <div style="position: absolute; left: 410px; top: 678px; line-height: 36px; padding: 0 20px; border: 1px solid #2CF7FE; background-color: #083866; color: #2CF7FE; cursor: pointer;" @click="CompareTunnels">数据对比</div>

        <div class="ProgramList" :style="'height: ' + pagesize * 30 + 'px'" @mouseover="pageMouseOver" @mouseleave="pageMouseLeave">
          <div class="program" v-for="(item, index) in getContrast" :key="index" :style="'top: ' + (0 + 30 * (index)) + 'px'">
            <dv-img class="bg-line" src="imgs/p2/line-bg.png"></dv-img>
            <div :class="'sign' + (needCompareIds.indexOf(item.Id) > -1 ? ' selected' : '')" @click="toggleSelect(item.Id)"></div>
            <span v-text="item.Name" :title="item.Id" @click="selectTunnel"></span>
            <span v-text="item.TestRate + '%'"></span>
            <span v-text="item.DataRate + '%'"></span>
            <span v-text="item.OverTime7Count + '处'"></span>
            <dv-bar-3d class="bar3d1" :value="item.DataRate1" height="16px" barcolorh="0" during="10" showtext="true"></dv-bar-3d>
            <dv-bar-3d class="bar3d2" :value="item.DataRate2" height="16px" barcolorh="30" during="10" showtext="true"></dv-bar-3d>
            <dv-bar-3d class="bar3d3" :value="item.DataRate3" height="16px" barcolors="5%" during="10" showtext="true"></dv-bar-3d>
            <dv-bar-3d class="bar3d4" :value="item.DataRate4" height="16px" barcolorh="190" during="10" showtext="true"></dv-bar-3d>
          </div>
          <div class="program" v-for="index in (Math.floor(getContrast.length / 5) + (getContrast.length % 5 > 0 ? 1 : 0))" :key="'row_' + index" :style="'top: ' + (0 + 30 * (index + getContrast.length - 1)) + 'px'">
            <dv-img class="bg-line" src="imgs/p2/line-bg.png"></dv-img>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import * as $ from 'jquery'
import * as echarts from 'echarts'
import dvImg from "./dv-img.vue"
import dataV from '../../bin/dataV'
import dvBar3d from "./dv-bar-3d.vue"

export default {
  data() {
    return {
      Id: 'RightProgramComponent',
      currentTime: dataV.DateTime.format('YYYY-mm-dd HH:MM:SS') + '　' + dataV.DateTime.getWeekCH(),
      pagesize: 5,				// 项目、隧道、缺陷列表显示记录数
      currentPage: 1,				// 项目、隧道、缺陷列表当前页码
      currentPageTimer: null,
      contrastIndex: 1, 			// 项目列表 或 隧道列表，对比维度：1-缺陷率对比，2-整改合格率对比
      pageToggleKey: true,		// 是否允许列表页自动翻页
      maxPage: dataV.Config.initPage.maxPage,

      echartsTimer: null,
      charts: {},

      CrackLength: 0,
      CrackRate: 0,
      DesignLength: 0,
      FaultCount1: 0,
      FaultCount2: 0,
      FaultCount3: 0,
      FaultCount4: 0,
      FaultCount5: 0,
      FaultCount6: 0,
      FaultRate1: 0,
      FaultRate2: 0,
      FaultRate3: 0,
      FaultRate4: 0,
      FaultRate5: 0,
      FaultRate6: 0,
      FaultTrend1: [],
      FaultTrend2: [],
      FaultTrend3: [],
      FaultTrend4: [],
      ProjectSimpleInfo: {Name: "", SiteName: "", Address: "", Describe: ""},
      TestLength: 0,
      TestRate: 0,
      TotalFaultBadCount: 0,
      TotalFaultBadFixRate: 0,
      TotalFaultBadRate: 0,
      TotalFaultCount: 0,
      TotalFaultFixRate: 0,
      TotalFaultRate: 0,
      TunnelCompareFault: [],
      TunnelCompareFix: [],
      TunnelCount: 0,
      VideoList: [],

      needCompareIds: []

    }
  },
  props: [],
  components: {
    "dv-img": dvImg,
    'dv-bar-3d': dvBar3d,
    // 'dv-bar-2d': dvBar2d,
  },
  methods: {

    toggleSelect(Id){
      const index = this.needCompareIds.indexOf(Id)
      if(index > -1) this.needCompareIds = this.needCompareIds.slice(0, index).concat(this.needCompareIds.slice(index + 1))
      else this.needCompareIds.push(Id)
    },

    CompareTunnels(){
      const Ids = this.needCompareIds.filter(item => item).join(',')
      if(Ids) window.RightListComponent.CompareTunnels(Ids)
      else dataV.ModelWindows.close()
    },

    selectPage(){		// 数据列表，手动换页
      this.currentPage = $(event.target).data('index');
      this.changePage(this.getContrast.length);
    },

    changePage(dataLength){
      var that = this;
      //
      if(this.currentPageTimer) clearInterval(this.currentPageTimer);
      var defectPageCount = Math.floor(dataLength / this.pagesize) + (dataLength % this.pagesize > 0 ? 1 : 0);
      if(defectPageCount > 1) {
        if(this.currentPageTimer) {
          clearInterval(this.currentPageTimer)
          this.currentPageTimer = null
        }
        this.currentPageTimer = setInterval(function(){
          if(that.pageToggleKey){
            var newpage = that.currentPage + 1;
            if(newpage > defectPageCount) newpage = 1;
            that.currentPage = newpage;
          }
        }, 5000);
      }
    },

    toggleContrastBtn(){
      this.contrastIndex = $(event.target).data('index'); 
    },
    pageMouseOver(){
      this.pageToggleKey = false;
    },
    pageMouseLeave(){
      this.pageToggleKey = true;
    },

    selectTunnel(){
      dataV.Page.Toggle(this, 3, $(event.target).attr('title'))
    },

    p2trend(){
      var that = this;
      //      
      if(!this.charts['chart_01']) this.charts['chart_01'] = echarts.init(document.getElementById('chart_p2_01'));
      this.charts['chart_01'].setOption({
          tooltip: { trigger: 'axis' }, grid: { left: '30', right: '0', bottom: '20', top: '10', },
          xAxis: { show: true, type: 'category', boundaryGap: false, data: that.FaultTrend1.map(item => item.Name) },
          yAxis: { show: true, type: 'value', splitLine: { show: false } },
          series: [
              { name: '衬砌厚度不足', type: 'line', data: that.FaultTrend1.map(item => item.FaultCount), smooth: true, showSymbol: false, hoverAnimation: false, lineStyle: { color: "#b30306", width: 1 }, areaStyle: { color: "#b30306", opacity: 0.3 } }
          ]
      });
      if(!this.charts['chart_02']) this.charts['chart_02'] = echarts.init(document.getElementById('chart_p2_02'));
      this.charts['chart_02'].setOption({
          tooltip: { trigger: 'axis' }, grid: { left: '30', right: '0', bottom: '20', top: '10', },
          xAxis: { show: true, type: 'category', boundaryGap: false, data: that.FaultTrend2.map(item => item.Name) },
          yAxis: { show: true, type: 'value', splitLine: { show: false } },
          series: [
              { name: '空洞、不密实', type: 'line', data: that.FaultTrend2.map(item => item.FaultCount), smooth: true, showSymbol: false, hoverAnimation: false, lineStyle: { color: "#33CBE9", width: 1 }, areaStyle: { color: "#33CBE9", opacity: 0.3 } }
          ]
      });
      if(!this.charts['chart_03']) this.charts['chart_03'] = echarts.init(document.getElementById('chart_p2_03'));
      this.charts['chart_03'].setOption({
          tooltip: { trigger: 'axis' }, grid: { left: '30', right: '0', bottom: '20', top: '10', },
          xAxis: { show: true, type: 'category', boundaryGap: false, data: that.FaultTrend3.map(item => item.Name) },
          yAxis: { show: true, type: 'value', splitLine: { show: false } },
          series: [
              { name: '钢筋缺失问题', type: 'line', data: that.FaultTrend3.map(item => item.FaultCount), smooth: true, showSymbol: false, hoverAnimation: false, lineStyle: { color: "#FFBC09", width: 1 }, areaStyle: { color: "#FFBC09", opacity: 0.3 } }
          ]
      });
      if(!this.charts['chart_04']) this.charts['chart_04'] = echarts.init(document.getElementById('chart_p2_04'));
      this.charts['chart_04'].setOption({
          tooltip: { trigger: 'axis' }, grid: { left: '30', right: '0', bottom: '20', top: '10', },
          xAxis: { show: true, type: 'category', boundaryGap: false, data: that.FaultTrend4.map(item => item.Name) },
          yAxis: { show: true, type: 'value', splitLine: { show: false } },
          series: [
              { name: '防裂钢筋网问题', type: 'line', data: that.FaultTrend4.map(item => item.FaultCount), smooth: true, showSymbol: false, hoverAnimation: false, lineStyle: { color: "#ffffff", width: 1 }, areaStyle: { color: "#ffffff", opacity: 0.3 } }
          ]
      });

      // 轮循tooltip
      if(this.echartsTimer) clearInterval(this.echartsTimer)
      var index = 0; //播放所在下标
      this.echartsTimer = setInterval(function() {
          that.charts['chart_01'].dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: index });
          that.charts['chart_02'].dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: index });
          that.charts['chart_03'].dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: index });
          that.charts['chart_04'].dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: index });
          index++;
          if(index >= that.FaultTrend4.length) {
              index = 0;
          }
      }, 3000);

    },

  },
  computed: {

    getContrast () {  // 项目的对比列表
      if(this.contrastIndex == 1){  // 返回 缺陷率对比列表
        return this.TunnelCompareFault
      }else{        // 返回 整改合格率对比列表
        return this.TunnelCompareFix
      }
    }

  },
  watch: {
    
    currentPage(){
      var targetTop = (this.currentPage - 1) * 5 * 30;
      if(dataV.Page.CurrentLevel === 1){
        $('#p2 .text .ProgramList').animate({
          scrollTop: targetTop + 'px'
        }, 'slow');
      }else if(dataV.Page.CurrentLevel === 2){
        $('#p2 .tunnelList .ProgramList').animate({
          scrollTop: targetTop + 'px'
        }, 'slow');
      }else if(dataV.Page.CurrentLevel === 3){
        targetTop = (this.currentPage - 1) * this.pagesize * 30;
        $('#p4 .defectList .pageList').animate({
          scrollTop: targetTop + 'px'
        }, 'slow');
      }
    },

    TunnelCompareFault(){
      this.changePage(this.getContrast.length);
    },

    FaultTrend1() {
      this.p2trend()
    }

  },
  created() {
			window[this.Id] =  this;
  },
  mounted() {
  },
};
</script>
