<style>
#dv-left-tunnel { position: absolute; left: -1100px; top: 0; width: 1000px; }
#dv-left-tunnel .bg { position: absolute; left: 0; top: 20px; width: 100%; }

#tunnel_caption { position: absolute; left: 75px; top: 130px; font-size: 27px; font-weight: 550; }
#logo { position: absolute; left: 70px; top: 199px; }
#info-back { position: absolute; left: 210px; top: 199px; }
#section_title { position: absolute; left: 230px; top: 210px; font-size: 16px; font-weight: bold; }
#section_description { position: absolute; left: 230px; top: 240px; text-align: left; width: 640px; line-height: 160%; height: 75px; overflow: hidden; }

#p_Left_3 { position: absolute; left: 0; top: 0; }
#p_Left_3 i { font-style: normal; }
#p_Left_3 .text{ font-family: 'Work Sans', sans-serif, "microsoft yahei"; font-size: 15px; }/* 'Teko' */
#p_Left_3 .text span{ color: #00ffff; }/* 'Teko' */
#p_Left_3 .bg01{ position: absolute; left: 90px; top: 194px; }
#p_Left_3 .department{ position: absolute; left: 113px; top: 230px; width: 730px; padding-bottom: 15px; }
#p_Left_3 .address{ position: absolute; left: 113px; top: 255px; width: 730px; }
#p_Left_3 .programType{ position: absolute; left: 713px; top: 255px; width: 430px; }
#p_Left_3 .smallTunnel-bg{ position: absolute; left: 108px; top: 290px; width: 772px; }
#p_Left_3 .smallTunnel{ position: absolute; left: 160px; top: 310px; width: 700px; }
#p_Left_3 .bg02{ position: absolute; left: 90px; top: 475px; }
#p_Left_3 .lable_tnlStartEnd  { position: absolute; left: 108px; top: 495px; width: 200px; font-weight: bold; }
#p_Left_3 .lable_tnlTotalLength{ position: absolute; left: 310px; top: 495px; width: 200px; font-weight: bold; }
#p_Left_3 .lable_tnlBetonLength{ position: absolute; left: 512px; top: 495px; width: 200px; font-weight: bold; }
#p_Left_3 .lable_tnlCheckLength{ position: absolute; left: 714px; top: 495px; width: 200px; font-weight: bold; }
#p_Left_3 .value_tnlStartEnd  { position: absolute; left: 108px; top: 515px; width: 200px; font-size: 34px; font-family: 'Fjalla One', sans-serif; }
#p_Left_3 .value_tnlTotalLength{ position: absolute; left: 310px; top: 510px; width: 200px; font-size: 42px; }
#p_Left_3 .value_tnlBetonLength{ position: absolute; left: 512px; top: 510px; width: 200px; font-size: 42px; }
#p_Left_3 .value_tnlCheckLength{ position: absolute; left: 714px; top: 510px; width: 200px; font-size: 42px; }
#p_Left_3 .bg03{ position: absolute; left: 90px; top: 595px; }
#p_Left_3 .bg05{ position: absolute; left: 120px; top: 750px; }
#p_Left_3 .bg05_bg{ position: absolute; left: 105px; top: 730px; }
#p_Left_3 .lable_defectRepairRate { position: absolute; left: 112px; top: 605px; width: 120px; font-weight: bold; }
#p_Left_3 .lable_keyDefectRepairRate { position: absolute; left: 112px; top: 735px; width: 200px; font-weight: bold; }
#p_Left_3 .value_defectRepairRate { position: absolute; left: 112px; top: 640px; width: 200px; font-size: 50px; font-weight: bold; }
#p_Left_3 .value_keyDefectRepairRate { position: absolute; left: 112px; top: 755px; width: 120px; font-size: 34px; font-weight: bold; }

#p_Left_3 .bg04-1{ position: absolute; left: 295px; top: 595px; }
#p_Left_3 .bg04-2{ position: absolute; left: 498px; top: 595px; }
#p_Left_3 .bg04-3{ position: absolute; left: 701px; top: 595px; }
#p_Left_3 .bg04-4{ position: absolute; left: 295px; top: 714px; }
#p_Left_3 .bg04-5{ position: absolute; left: 498px; top: 714px; cursor: pointer; }
#p_Left_3 .bg04-6{ position: absolute; left: 701px; top: 714px; cursor: pointer; }
#p_Left_3 .lable_thickCount { position: absolute; left: 305px; top: 598px; width: 190px; font-weight: bold; }
#p_Left_3 .lable_otherCount { position: absolute; left: 508px; top: 598px; width: 190px; font-weight: bold; }
#p_Left_3 .lable_rebarCount { position: absolute; left: 711px; top: 598px; width: 190px; font-weight: bold; }
#p_Left_3 .lable_betonCount { position: absolute; left: 305px; top: 717px; width: 190px; font-weight: bold; }
#p_Left_3 .lable_betonCount2 { position: absolute; left: 508px; top: 717px; width: 190px; font-weight: bold; pointer-events: none; }
#p_Left_3 .lable_betonCount3 { position: absolute; left: 711px; top: 717px; width: 190px; font-weight: bold; pointer-events: none; }
#p_Left_3 .value_thickCount { position: absolute; left: 305px; top: 645px; width: 190px; font-size: 42px; font-weight: bold; }
#p_Left_3 .value_otherCount { position: absolute; left: 508px; top: 645px; width: 190px; font-size: 42px; font-weight: bold; }
#p_Left_3 .value_rebarCount { position: absolute; left: 711px; top: 645px; width: 190px; font-size: 42px; font-weight: bold; }
#p_Left_3 .value_betonCount { position: absolute; left: 305px; top: 764px; width: 190px; font-size: 42px; font-weight: bold; }
#p_Left_3 .value_betonCount2 { position: absolute; left: 508px; top: 764px; width: 190px; font-size: 42px; font-weight: bold; pointer-events: none; }
#p_Left_3 .value_betonCount3 { position: absolute; left: 711px; top: 764px; width: 190px; font-size: 42px; font-weight: bold; pointer-events: none; }
#p_Left_3 .bg05-1{ position: absolute; left: 414px; top: 630px; width: 72px; }
#p_Left_3 .bg05-2{ position: absolute; left: 617px; top: 630px; width: 72px; }
#p_Left_3 .bg05-3{ position: absolute; left: 820px; top: 630px; width: 72px; }
#p_Left_3 .bg05-4{ position: absolute; left: 414px; top: 749px; width: 72px; }
#p_Left_3 .bg05-5{ position: absolute; left: 617px; top: 749px; width: 72px; pointer-events: none; }
#p_Left_3 .bg05-6{ position: absolute; left: 820px; top: 749px; width: 72px; pointer-events: none; }
#p_Left_3 .rate_thickCount { position: absolute; left: 388px; top: 652px; width: 124px; font-size: 22px; text-align: center; font-weight: bold; }
#p_Left_3 .rate_otherCount { position: absolute; left: 591px; top: 652px; width: 124px; font-size: 22px; text-align: center; font-weight: bold; }
#p_Left_3 .rate_rebarCount { position: absolute; left: 794px; top: 652px; width: 124px; font-size: 22px; text-align: center; font-weight: bold; }
#p_Left_3 .rate_betonCount { position: absolute; left: 388px; top: 771px; width: 124px; font-size: 22px; text-align: center; font-weight: bold; }
#p_Left_3 .rate_betonCount2 { position: absolute; left: 591px; top: 771px; width: 124px; font-size: 22px; text-align: center; font-weight: bold; pointer-events: none; }
#p_Left_3 .rate_betonCount3 { position: absolute; left: 794px; top: 771px; width: 124px; font-size: 22px; text-align: center; font-weight: bold; pointer-events: none; }
#p_Left_3 .rate_thickCount span { color: #FE0001; }
#p_Left_3 .rate_otherCount span { color: #0055FE; }
#p_Left_3 .rate_rebarCount span { color: #FFA518; }
#p_Left_3 .rate_betonCount span { color: #9094A0; }
#p_Left_3 .rate_betonCount3 span { color: #8428A1; }

#p_Left_3 .bg06{ position: absolute; left: 90px; top: 835px; width: 268px; }
#p_Left_3 .bg07{ position: absolute; left: 375px; top: 835px; width: 253px; }
#p_Left_3 .bg08{ position: absolute; left: 644px; top: 835px; width: 253px; }
#p_Left_3 .lable_defectInfo { position: absolute; left: 108px; top: 840px; width: 200px; font-weight: bold; }
#p_Left_3 .lable_keyDefectInfo { position: absolute; left: 393px; top: 840px; width: 200px; font-weight: bold; }
#p_Left_3 .lable_tunnelInfo { position: absolute; left: 662px; top: 840px; width: 200px; font-weight: bold; }
#p_Left_3 .value_defectInfo    { position: absolute; left: 108px; top: 880px; width: 230px; font-weight: bold; }
#p_Left_3 .value_keyDefectInfo { position: absolute; left: 393px; top: 880px; width: 215px; font-weight: bold; }
#p_Left_3 .value_tunnelInfo    { position: absolute; left: 662px; top: 880px; width: 215px; font-weight: bold; }
#p_Left_3 .value_defectInfo    span { font-size: 36px; }
#p_Left_3 .value_keyDefectInfo span { font-size: 36px; }
#p_Left_3 .value_tunnelInfo    span { font-size: 36px; }
#p_Left_3 .value_defectInfo    div:nth-child(1),
#p_Left_3 .value_tunnelInfo    div:nth-child(1),
#p_Left_3 .value_keyDefectInfo div:nth-child(1) { position: absolute; left: 0; top: 0; width: 120px; font-weight: bold; text-align: left; }
#p_Left_3 .value_defectInfo    i { position: absolute; left: 105px; top: 0; font-size: 36px; font-style: normal; color: #5d91a7; }
#p_Left_3 .value_tunnelInfo    i,
#p_Left_3 .value_keyDefectInfo i { position: absolute; left: 95px; top: 0; font-size: 36px; font-style: normal; color: #5d91a7; }
#p_Left_3 .value_defectInfo    div:nth-child(3),
#p_Left_3 .value_tunnelInfo    div:nth-child(3),
#p_Left_3 .value_keyDefectInfo div:nth-child(3) { position: absolute; right: 0; top: 0; width: 120px; font-weight: bold; text-align: right; }
#p_Left_3 .value_defectInfo div span,
#p_Left_3 .value_tunnelInfo div span,
#p_Left_3 .value_keyDefectInfo div span{ font-family: 'Fjalla One', sans-serif; font-size: 32px; margin-right: 5px; }

</style>

<template>
  <div id="dv-left-tunnel">
    <div>
      <dv-img class="bg" src="./imgs/img/left/left.json" type="svg" loop="true"></dv-img>
      <div id="tunnel_caption" v-text="Name"></div>
    </div>

    <div id="p_Left_3" class="page">
      <dv-img class="bg01" src="imgs/p3/f189986e-908b-43ea-9c2f-8e88d5965624.png"></dv-img>
      
      <div class="text department">承建单位：<i v-text="ProjectSimpleInfo.SiteName"></i></div>
      <div class="text address">建设地点：<i v-text="ProjectSimpleInfo.Address"></i></div>
      <div class="text programType">项目类型：<i v-text="ProjectSimpleInfo.BelongCategory"></i></div>
      
      <dv-img class="smallTunnel-bg" src="imgs/p4/5a60e193-2126-475f-9bf9-075aa7def098.png"></dv-img>
      <dv-smalltunnel left="125" top="300" width="780" height="115" divcount="2" divlength="10" :code="tempTunnelInfoPath.code" :startkm="tempTunnelInfoPath.startKM" :startm="tempTunnelInfoPath.startM" :length="tempTunnelInfoPath.planLength" :defects="tempTunnelInfoPath.defects"></dv-smalltunnel>
      
      <dv-img class="bg02" src="imgs/p3/7de0ba79-5edb-4ff7-ba79-e26c1f26f421.png"></dv-img>
      <div class="text lable_tnlStartEnd">起始里程桩号</div>
      <div class="text lable_tnlTotalLength">规划总长（M）</div>
      <div class="text lable_tnlBetonLength">已检总长（M）</div>
      <div class="text lable_tnlCheckLength">衬砌总长（M）</div>
      <div class="text value_tnlStartEnd"><span v-text="PileNumCode"></span></div>
      <div class="text value_tnlTotalLength"><span v-text="DesignLength"></span></div>
      <div class="text value_tnlBetonLength"><span v-text="TestLength"></span></div>
      <div class="text value_tnlCheckLength"><span v-text="CrackLength"></span></div>
      
      <dv-img class="bg03" src="imgs/p101/03-s-bg.png"></dv-img>
      <div class="text lable_defectRepairRate">缺陷修复率</div>
      <div class="text lable_keyDefectRepairRate">重大缺陷修复率</div>
      <div class="text value_defectRepairRate"><span v-text="TotalFaultFixRate + '%'"></span></div>
      <div class="text value_keyDefectRepairRate"><span v-text="TotalFaultBadFixRate + '%'"></span></div>
      
      <dv-img class="bg04-1" src="imgs/p101/03-s2-bg.png"></dv-img>
      <dv-img class="bg04-2" src="imgs/p101/03-s2-bg.png"></dv-img>
      <dv-img class="bg04-3" src="imgs/p101/03-s2-bg.png"></dv-img>
      <dv-img class="bg04-4" src="imgs/p101/03-s2-bg.png"></dv-img>
      <dv-img class="bg04-5" src="imgs/p101/03-s2-bg.png" @click="GetSevens"></dv-img>
      <dv-img class="bg04-6" src="imgs/p101/03-s2-bg.png" @click="GetMonths"></dv-img>
      <div class="text lable_thickCount">厚度不足（处）/占比</div>
      <div class="text lable_otherCount">空洞、不密实（处）/占比</div>
      <div class="text lable_rebarCount">钢筋缺失（处）/占比</div>
      <div class="text lable_betonCount">防裂钢筋网（处）/占比</div>
      <div class="text lable_betonCount2">7天超时确认（处）/占比</div>
      <div class="text lable_betonCount3">30天超时确认（处）/占比</div>
      <div class="text value_thickCount"><span v-text="FaultCount1"></span></div>
      <div class="text value_otherCount"><span v-text="FaultCount4"></span></div>
      <div class="text value_rebarCount"><span v-text="FaultCount2"></span></div>
      <div class="text value_betonCount"><span v-text="FaultCount3"></span></div>
      <div class="text value_betonCount2"><span v-text="FaultCount5"></span></div>
      <div class="text value_betonCount3"><span v-text="FaultCount6"></span></div>
      <dv-img class="bg05-1" src="imgs/p101/03-yuan-1.png"></dv-img>
      <dv-img class="bg05-2" src="imgs/p101/03-yuan-2.png"></dv-img>
      <dv-img class="bg05-3" src="imgs/p101/03-yuan-3.png"></dv-img>
      <dv-img class="bg05-4" src="imgs/p101/03-yuan-4.png"></dv-img>
      <dv-img class="bg05-5" src="imgs/p101/03-yuan-5.png"></dv-img>
      <dv-img class="bg05-6" src="imgs/p101/03-yuan-6.png"></dv-img>
      <div class="text rate_thickCount"><span v-text="(FaultCount1 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_otherCount"><span v-text="(FaultCount4 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_rebarCount"><span v-text="(FaultCount2 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_betonCount"><span v-text="(FaultCount3 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_betonCount2"><span v-text="(FaultCount5 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_betonCount3"><span v-text="(FaultCount6 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      
      <dv-img class="bg06" src="imgs/p3/c71f4383-bfe3-45c7-9488-2f7583f62f31.png"></dv-img>
      <dv-img class="bg07" src="imgs/p3/c71f4383-bfe3-45c7-9488-2f7583f62f31.png"></dv-img>
      <dv-img class="bg08" src="imgs/p3/c71f4383-bfe3-45c7-9488-2f7583f62f31.png"></dv-img>
      <div class="text lable_defectInfo">缺陷 / 缺陷率</div>
      <div class="text lable_keyDefectInfo">重大缺陷 / 重大缺陷率</div>
      <div class="text lable_tunnelInfo">缺陷修复 / 重大缺陷修复</div>
      <div class="text value_defectInfo">
        <div><span v-text="TotalFaultCount"></span>处</div>
        <i>/</i>
        <div><span v-text="TotalFaultRate"></span>%</div>
      </div>
      <div class="text value_keyDefectInfo">
        <div><span v-text="TotalFaultBadCount"></span>处</div>
        <i>/</i>
        <div><span v-text="TotalFaultBadRate"></span>%</div>
      </div>
      <div class="text value_tunnelInfo">
        <div><span v-text="TotalFaultFixCount"></span></div>
        <i>/</i>
        <div><span v-text="TotalFaultBadFixCount"></span></div>
      </div>
    </div>

  </div>
</template>

// <script>
// import * as $ from 'jquery';
import dataV from '../../bin/dataV'
import dvImg from "./dv-img.vue"
// import dvSmallTunnel from './dv-smalltunnel'
const dvSmallTunnel = () => import('./dv-smalltunnel.vue')

export default {
  data() {
    return {
      Id: 'LeftTunnelComponent',

      AreaName: dataV.Config.AreaName,
      ImgBaseUrl: dataV.Config.baseUrl.img,

      currentPageLevel: dataV.Config.initPage.level,
      currentArea: dataV.Config.AreaName,
      currentProgram: dataV.Config.initPage.program,
      currentTunnel: dataV.Config.initPage.tunnel,
      currentTunnelID: dataV.Config.initPage.tunnelId,
      currentDefect: dataV.Config.initPage.defect,

      BuildLength: 0,
      CrackLength: 0,
      DesignLength: 0,
      FaultCount1: 0,
      FaultCount2: 0,
      FaultCount3: 0,
      FaultCount4: 0,
      FaultCount5: 0,
      FaultCount6: 0,
      FaultDetailList1: [],
      FaultDetailList2: [],
      FaultDetailList3: [],
      FaultDetailList4: [],
      FaultRate1: 0,
      FaultRate2: 0,
      FaultRate3: 0,
      FaultRate4: 0,
      FaultRate5: 0,
      FaultRate6: 0,
      FaultTrend1: [],
      FaultTrend2: [],
      FaultTrend3: [],
      FaultTrend4: [],
      Name: "",
      PileNumCode: "",
      ProjectSimpleInfo: {BelongCategory: "", SiteName: "", Address: ""},
      TestLength: 0,
      TestRate: 0,
      TotalFaultBadCount: 0,
      TotalFaultBadFixRate: 0,
      TotalFaultBadRate: 0,
      TotalFaultCount: 0,
      TotalFaultFixRate: 0,
      TotalFaultRate: 0,
      TotalFaultFixCount: '-',
      TotalFaultBadFixCount: '-',
      ModelData: {
        GoodKiloMetreInfo: null,
        OverKiloMetreInfo: {startKiloMetre: 99, startMetre: 482, endKiloMetre: 99, endMetre: 818},
        ProjectDepartment: "湖杭铁路项目经理部二分部",
        ProjectName: "湖杭铁路二分部",
        ProjectType: "铁路",
        SuiDaoDividerInfo: [],
        TunnelAddress: "浙江省富阳市",
        TunnelCode: "DK",
        TunnelEndKiloMetre: 0,
        TunnelEndMetre: 0,
        TunnelName: "羊岩坞二号隧道出口",
        TunnelStartKiloMetre: 0,
        TunnelStartMetre: 0,
        ViewKiloMetreInfo: {startKiloMetre: 0, startMetre: 0, endKiloMetre: 0, endMetre: 0},
        WorkKiloMetreInfo: {startKiloMetre: 0, startMetre: 0, endKiloMetre: 0, endMetre: 0},
        bugs: [],
        checkLines: [],
      },

      tempTunnelInfoPath: {
				id: '',
				name: '',
				address: '',
				code: '',
				startKM: 0,
				startM: 0,
				endKM: 0,
				endM: 0,
				planLength: 0,
				buildLength: 0,
				checkLength: 0,
				betonLength: 0,
				explains: '',
				defects: [],
        checkLines: [],
        suiDaoDividerInfo: []
      },
            
    };
  },
  props: [],
  created() {
			window[this.Id] =  this;
  },
  methods: {

      GetSevens(){
				this.$api.request({ Vue: this, apiName: 'checkOver', query: { tempId: dataV.Page.CurrentTunnelId, datatype: 2 } }).then(response => {
					if (response.state === 'success') {
						const json = JSON.parse(response.data)
						window.CenterWindowsComponent.set('sevens', json)
					} else {
						alert(response.message)
					}
				})
			},

      GetMonths(){
				this.$api.request({ Vue: this, apiName: 'fixOver', query: { tempId: dataV.Page.CurrentTunnelId, datatype: 2 } }).then(response => {
					if (response.state === 'success') {
						const json = JSON.parse(response.data)
						window.CenterWindowsComponent.set('months', json)
					} else {
						alert(response.message)
					}
				})
			},
      
  },
  watch: {
    Name(){
      this.tempTunnelInfoPath.id = '',
      this.tempTunnelInfoPath.name = this.ModelData.TunnelName,									// 名称
      this.tempTunnelInfoPath.address = this.ModelData.TunnelAddress,								// 地点
      this.tempTunnelInfoPath.code = this.ModelData.TunnelCode,
      this.tempTunnelInfoPath.startKM = this.ModelData.TunnelStartKiloMetre,
      this.tempTunnelInfoPath.startM = this.ModelData.TunnelStartMetre,
      this.tempTunnelInfoPath.endKM = this.ModelData.TunnelEndKiloMetre,
      this.tempTunnelInfoPath.endM = this.ModelData.TunnelEndMetre,
      this.tempTunnelInfoPath.planLength = this.DesignLength,
      this.tempTunnelInfoPath.buildLength = this.BuildLength,
      this.tempTunnelInfoPath.checkLength = this.TestLength,
      this.tempTunnelInfoPath.betonLength = this.CrackLength,
      this.tempTunnelInfoPath.explains = '',
      this.tempTunnelInfoPath.defects = this.ModelData.bugs,
      this.tempTunnelInfoPath.checkLines = this.ModelData.checkLines,
      this.tempTunnelInfoPath.suiDaoDividerInfo = this.ModelData.SuiDaoDividerInfo
      
      window.dataV.CurrentTunnelInfo = this.tempTunnelInfoPath
    }
  },
  computed: {
    _TotalFaultCount(){
      let t = this.FaultCount1 + this.FaultCount2 + this.FaultCount3 + this.FaultCount4
      if(t == 0) t = 1
      return t
    }
  },
  mounted() {
    // 注册弹出窗口
  },
  components: {
    "dv-img": dvImg,
    'dv-smalltunnel': dvSmallTunnel
  },
};
</script>
