<style>
	#dv-right-menu {
		position: absolute;
		right: 0;
		top: 220px;
		height: 700px;
		width: 85px;
		pointer-events: none;
	}
	
	#dv-right-menu .bg2 {
		position: absolute;
		right: 955px;
		top: -2px;
		pointer-events: all;
	}
	#dv-right-menu .pointer {
		cursor: pointer;
	}
</style>

<template>
	<div id="dv-right-menu" :style="currentPageLevel != 3 ? 'display: none' : ''">
		<dv-img class="bg2 pointer" :src="'imgs/p101/menu001.png'" @click="tellme"></dv-img>
	</div>
</template>

<script>
	// import * as $ from 'jquery'
	import dataV from '../../bin/dataV'
	import dvImg from './dv-img'

	export default {
		data: function() {
			return {
				Id: 'RightMenuComponent',

				currentPageLevel: dataV.Config.initPage.level,		// 大屏当前页面层级，1-全国、2-项目部
				currentDefect: dataV.Config.initPage.defect,
			}
		},
		props: [],
		created() {
			window[this.Id] =  this;
		},
		methods: {
			tellme(){
				// console.log(event.offsetX, event.offsetY);
				if(event.offsetY < 160) this.toggle2('衬砌厚度不足')
				else if(event.offsetY < 316) this.toggle2('钢筋缺失问题')
				else if(event.offsetY < 471) this.toggle2('其他问题')
				else this.toggle2('防裂钢筋网问题')
			},

			sync(){
				this.currentPageLevel = dataV.Page.CurrentLevel
			},
			
			toggle2: function(e) {
				dataV.Page.Toggle(this, 3, dataV.Page.CurrentTunnelId)
				this.currentDefect = typeof(e) === 'string' ? e : e.target.title;
				// console.log(this.currentPageLevel, this.currentDefect)
			},

			goUp: function(){
				if(this.currentPageLevel > 1) {
					dataV.Page.Toggle(this, 2, dataV.Page.CurrentId || window.ApiData.index.ProjectList[0].Id)
				}
			},
			
		},
		watch: {
			currentPageLevel(){
				// console.log(this.currentPageLevel)
				if(this.currentPageLevel != 3) this.currentDefect = dataV.Config.initPage.defect
			},
			currentDefect(){
				window.RightTunnelComponent.currentDefect = this.currentDefect
			}
		},
		mounted () {
			
		},
		components: {
			'dv-img': dvImg
		}

	}
</script>
