<style>
	#dv-left-flow{
		position: absolute;
		left: 1030px;
		top: 70px;
		width: 300px;
		height: 1000px;
		overflow: hidden;
		opacity: 0;
	}
	
	#dv-left-flow .bg{
		position: absolute;
		left: -4px;
		top: 15px;
		width: 275px;
		height: 950px;
	}
	
	#dv-left-flow .keyworks{
		position: absolute;
		left: 40px;
		top: 90px;
		width: 190px;
		max-height: 210px;
		overflow: hidden;
		text-align: justify;
		line-height: 170%;
		font-size: 15px;
	}
	
	#dv-left-flow .keyworks .item{
		padding-bottom: 10px;
	}
	
	#dv-left-flow .keyworks .item .title{
		color: #00ffff;
		font-weight: bold;
	}
	
	#dv-left-flow .transroads{
		position: absolute;
		left: 40px;
	}
	#dv-left-flow .transroads.xy-0{
		top: 374px;
	}
	#dv-left-flow .transroads.xy-1{
		top: 564px;
	}
	#dv-left-flow .transroads.xy-2{
		top: 760px;
	}
	#dv-left-flow .transroads .title{
		position: absolute;
		left: 0;
		top: 0;
		font-size: 16px;
		text-align: left;
		line-height: 300%;
		color: #ffffff;
		width: 180px;
		cursor: pointer;
	}
	#dv-left-flow .transroads .img{
		position: absolute;
		left: 0;
		top: 44px;
		width: 185px;
		height: 71px;
		cursor: pointer;
	}
	#dv-left-flow .transroads .bugrate{
		position: absolute;
		left: 0;
		top: 126px;
		width: 100px;
	}
	#dv-left-flow .transroads .modrate{
		position: absolute;
		left: 0;
		top: 150px;
		width: 150px;
	}
	#dv-left-flow .transroads .bar .bar3d{
		position: absolute;
		left: 68px;
		top: 4px;
		width: 80px;
	}
	#dv-left-flow .transroads .bar .bg-red,
	#dv-left-flow .transroads .bar .bg-blue{
		position: absolute;
		left: 68px;
		top: 4px;
	}
	#dv-left-flow .transroads .value{
		position: absolute;
		left: 136px;
		top: 0;
		width: 60px;
		text-align: right;
		color: #45edff;
		/* font-family: 'Work Sans', sans-serif, "microsoft yahei"; */
		font-family: 'Fjalla One', sans-serif;
	}
	#dv-left-flow .showBox{
		opacity: 1;
	}

	#dv-left-flow .CurrentLightBox1 { position: absolute; left: -10px; top: 10px; opacity: 0; pointer-events: none; z-index: 99999; }
	#dv-left-flow .CurrentLightBox2 { position: absolute; left: -10px; top: 300px; opacity: 0; pointer-events: none; z-index: 99999; }

</style>

<template>
	<div id="dv-left-flow" :style="'display: ' + (currentPageLevel == 3 ? 'none' : '')">
		<dv-img target="dv-left-flow-bg" class="bg" src="imgs/img/text/text.json" type="svg"></dv-img>
		<div v-if="isShow" class="showBox">
			<div class="keyworks">
				<div>
					<div class="item" v-for="(keywork, index) in keyWorks" :key="index">
						<span class="title" v-text="keywork.title"></span>
						<span class="content" v-text="keywork.content"></span>
					</div>
				</div>
			</div>
			<div v-for="(road, index) in top3" :key="index" :class="'transroads xy-' + index">
				<div class="title" v-text="road.Name" :data-title="road.Name" :data-id="road.Id" @click="link2Program"></div>
				<dv-img class="img" :src="ImgBaseUrl + road.ShowPic" :data-title="road.Name" :data-id="road.Id" @click="link2Program"></dv-img>
				<div class="bugrate">
					<span class="lable">总 缺 陷 率：</span>
					<span class="bar">
						<dv-bar-3d class="bar3d" :value="road.TotalFaultRate" height="12px" barcolorh="0" during="3"></dv-bar-3d>
					</span>
					<span class="value" v-text="road.TotalFaultRate + '%'"></span>
				</div>
				<div class="modrate">
					<span class="lable">整改合格率：</span>
					<span class="bar">
						<dv-bar-3d class="bar3d" :value="road.TotalFixRate" height="12px" barcolorh="220" during="3"></dv-bar-3d>
					</span>
					<span class="value" v-text="road.TotalFixRate + '%'"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as $ from 'jquery'
	import dataV from '../../bin/dataV'
	import dvImg from "./dv-img.vue"
	import dvBar3d from "./dv-bar-3d.vue"

	export default {
		data: function(){
			return {	
				Id: 'LeftFlowComponent',

				currentPageLevel: dataV.Config.initPage.level,		// 大屏当前页面层级，1-全国、2-项目部
				currentArea: dataV.Config.initPage.area,
				currentProgram: dataV.Config.initPage.program,
				currentTunnel: dataV.Config.initPage.tunnel,
				currentDefect: dataV.Config.initPage.defect,
		
				keyWorks: dataV.Config.keyWorks,
				
				top3: window.ApiData.index.SignProjectList,

				ImgBaseUrl: dataV.Config.baseUrl.img,
				during: 5000,
				isShow: true
			}
		},
		props: ['data'],
		created() {
			window[this.Id] =  this;
		},
		methods: {
			
			sync(){
				this.currentPageLevel = dataV.Page.CurrentLevel
			},

			init(){
				var that = this;
				$('#dv-left-flow .showBox').css('opacity', 0).animate({
					opacity: 1
				}, 1000, function(){
					that.keyWorksEls = $('#dv-left-flow .keyworks .item');
					if($('#dv-left-flow .keyworks>div').height() > $('#dv-left-flow .keyworks').height()){
						setTimeout(that.animate, that.during);
					}
				})
			},
			animate(){
				var that = this;
				this.keyWorksEls = $('.keyworks .item');
				var $currentEl = $(this.keyWorksEls[0]);
				$('#dv-left-flow .keyworks>div').append($currentEl.clone());
				// var height = $currentEl.height();
				$currentEl.animate({
					overflow: 'hidden',
					opacity: 0,
					height: 0,
					'margin-top': '-10px'
				}, 500, function(){
					$currentEl.remove();
					setTimeout(that.animate, that.during);
				})
			},
			
			link2Program(){
				dataV.Page.Toggle(this, 2, $(event.target).data('id'));
			},
			
		},
		watch: {
			top3(){
				// console.log(this.top3)
			}
		},
		mounted: function(){
			this.animate()

			setTimeout(function(){
				$('#dv-left-flow').animate({
					opacity: 1,
				}, 500)
			}, 1000)
			
			// 加载api数据
			// dataV.API.TestingCenter({that: this, item: 'info'});
			// dataV.API.Top3({that: this, item: 'top3'});
		},
		components: {
			'dv-img': dvImg,
			'dv-bar-3d': dvBar3d,
		}
		
	}
</script>
