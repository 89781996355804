<style>
#dv-footer {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 3840px;
}
#dv-footer .bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 3840px;
}
</style>

<template>
  <div id="dv-footer">
    <dv-img class="bg" src="./imgs/foot.png"></dv-img>
  </div>
</template>

<script>
import dvImg from "./dv-img.vue";

export default {
  data() {
    return {};
  },
  props: [],
  methods: {},
  mounted() {},
  components: {
    "dv-img": dvImg
  },
};
</script>
