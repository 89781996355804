import * as $ from 'jquery'
import dataV from './dataV'

const px = 1100, during = 800

const panels = () => {
	return [
		window.LeftIndexComponent,
		window.LeftProgramComponent,
		window.LeftTunnelComponent,

		window.RightIndexComponent,
		window.RightProgramComponent,
		window.RightTunnelComponent,
		window.RightMenuComponent,
	]
}

const hasOpenPanel = () => {
	let has = false
	panels().forEach(panel => {
		if (panel.Id.toLowerCase().indexOf('left') === 0) {
			if ($(panel.$el).offset().left === 0) has = true
		} else if (panel.Id.toLowerCase().indexOf('right') === 0) {
			if ($(panel.$el).offset().left < (3840 - px / 2)) has = true
		}
	})
	return has
}

const Page = {
	CurrentId: '',
	CurrentTunnelId: '',
	CurrentLevel: 1,

	Toggle: (Vue, level = 1, Id = '') => {
		Page.CurrentLevel = level
		if(level === 2) Page.CurrentId = Id
		if(level === 3) Page.CurrentTunnelId = Id

		syncPanels()

		dataV.ModelWindows.close()

		// console.log('CurrentLevel：', Page.CurrentLevel, 'CurrentId：', Page.CurrentId, 'CurrentTunnelId：', Page.CurrentTunnelId)

		$(window.LeftFlowComponent.$el).animate({
			opacity: 0,
		}, during * 2, function(){
			$(window.LeftFlowComponent.$el).animate({
				opacity: 1,
			}, during * 2)		
		})
	
		switch (level) {
			case 1:
				togglePanel([window.LeftIndexComponent.Id, window.RightIndexComponent.Id])
				break
			case 2:
				if (!window.ApiData[Id]) {
					Vue.$api.request({ Vue, apiName: 'program', query: { projectInfoId: Id } }).then(response => {
						window.ApiData[Id] = (() => { try { return JSON.parse(response.data) } catch (e) { return response.data } })()
						if (response.state === 'success') {
							loadProgram(window.ApiData[Id])
						} else {
							console.log('获取项目数据失败：', response.message)
							alert('获取项目数据失败：' + response.message)
						}
					})
				} else loadProgram(window.ApiData[Id])
				break
			case 3:
				if (!window.ApiData[Id]) {
					Vue.$api.request({ Vue, apiName: 'tunnel', query: { tunnelInfoId: Id } }).then(response => {
						window.ApiData[Id] = (() => { try { return JSON.parse(response.data) } catch (e) { return response.data } })()
						if (response.state === 'success') {
							loadTunnel(window.ApiData[Id])
						} else {
							console.log('获取隧道数据失败：', response.message)
							alert('获取隧道数据失败：' + response.message)
						}
					})
				} else loadTunnel(window.ApiData[Id])
				break
		}
	}
}

const syncPanels = () => {
	window.CenterComponent.sync()
	window.LeftFlowComponent.sync()
	window.RightListComponent.sync()
	window.RightMenuComponent.sync()
}

const loadProgram = (data) => {
	updateComponetData(window.LeftProgramComponent, data)
	updateComponetData(window.RightProgramComponent, data)
	togglePanel([window.LeftProgramComponent.Id, window.RightProgramComponent.Id])
}

const loadTunnel = (data) => {
	updateComponetData(window.LeftTunnelComponent, data)
	updateComponetData(window.RightTunnelComponent, data)
	togglePanel([window.LeftTunnelComponent.Id, window.RightTunnelComponent.Id, window.RightMenuComponent.Id])
}

const updateComponetData = (VueEl, data) => {
	// console.log(VueEl, data)
	Object.keys(data).forEach(key => {
		// console.log(key)
		VueEl[key] = data[key]
	})
}

const togglePanel = showIds => {
	// 检测 所有面板状态
	const needDelay = hasOpenPanel()
	if(needDelay) {
		dataV.Sound.play('滑出')
		setTimeout(function(){
			dataV.Sound.play('滑入')
		}, during)
	}

	panels().forEach(panel => {
		var direct = ''
		if (panel.Id.toLowerCase().indexOf('left') === 0) direct = 'left'
		if (panel.Id.toLowerCase().indexOf('right') === 0) direct = 'right'
		if (panel.Id.toLowerCase().indexOf('top') === 0) direct = 'top'
		if (panel.Id.toLowerCase().indexOf('bottom') === 0) direct = 'bottom'

		panelAnimate(panel, direct, 'close')

		// 打开需要的面板
		if (showIds.indexOf(panel.Id) > -1) setTimeout(() => panelAnimate(panel, direct, 'open'), needDelay ? during + 300 : 0)
	})
}

const panelAnimate = (VueEl, direct, key = 'close') => {		// key = close || open
	let targetPX = 0;
	switch (direct) {
		case 'left':
			targetPX = key === 'open' ? 0 : -1100
			break
		case 'right':
			targetPX = key === 'open' ? 0 : -1100
			break
		case 'top':
			targetPX = key === 'open' ? 0 : -150
			break
		case 'bottom':
			targetPX = key === 'open' ? 0 : -200
			break
	}

	// console.log('here', VueEl.Id.toLowerCase(), direct, targetPX)
	const targetAnimate = () => {
		return {
			[direct]: targetPX + 'px'
		}
	}

	$(VueEl.$el).animate(targetAnimate(), during)
}

// Object.defineProperties(Page, {
// 	CurrentLevel: {
// 		get: function () {
// 			const value = this._CurrentLevel ? parseInt(this._CurrentLevel) : 1
// 			console.log('Get CurrentLevel：', value)
// 			return value
// 		},
// 		set: function (value) {
// 			this._CurrentLevel = value
// 			console.log('Set CurrentLevel：', value)

// 			this.togglePage()
// 		}
// 	}
// })

if (!window.Page) window.Page = Page;

export default Page