<style>
  #dv-right-tunnel { position: absolute; right: -1100px; top: 0; width: 1000px; }
  #dv-right-tunnel .bg { position: absolute; right: 0; top: 20px; width: 100%; }
  #dv-right-tunnel .time { position: absolute; right: 70px; top: 140px; width: 400px; text-align: right; font-size: 18px; font-weight: 600; font-style: normal; }

  #dv-right-tunnel .text{ position: absolute; left: 120px; top: 205px; font-size: 16px; font-weight: bold; width: 810px; }
  #dv-right-tunnel .text span{ color: #00ffff; font-family: 'Work Sans', sans-serif, "microsoft yahei"; }/* 'Teko' */

  #p4 { font-weight: bold; font-family: 'Work Sans', sans-serif, "microsoft yahei"; }
  #p4 span { color: #00ffff; }
  #p4 .bg01{ position: absolute; left: 130px; top: 200px; width: 100px; height: 36px; }
  #p4 .filter{ filter: grayscale(80%) }
  #p4 .label_trend{ position: absolute; left: 140px; top: 207px; width: 200px; font-size: 16px; font-weight: bold; }
  #p4 .chartTime{ position: absolute; left: 740px; top: 212px; font-size: 14px; font-weight: normal; width: 200px; text-align: right; }
  #p4 .bg02{ position: absolute; left: 130px; top: 250px; }
  #p4 .bg03{ position: absolute; left: 545px; top: 250px; }
  #p4 .bg04{ position: absolute; left: 130px; top: 455px; }
  #p4 .bg05{ position: absolute; left: 545px; top: 455px; }
  #p4 .bg02-title{ position: absolute; left: 135px; top: 255px; }
  #p4 .bg04-title{ position: absolute; left: 550px; top: 255px; }
  #p4 .bg05-title{ position: absolute; left: 135px; top: 460px; }
  #p4 .bg03-title{ position: absolute; left: 550px; top: 460px; }
  #p4 .label_trend_01{ position: absolute; left: 150px; top: 256px; width: 200px; }
  #p4 .label_trend_03{ position: absolute; left: 565px; top: 256px; width: 200px; }
  #p4 .label_trend_04{ position: absolute; left: 150px; top: 461px; width: 200px; }
  #p4 .label_trend_02{ position: absolute; left: 565px; top: 461px; width: 200px; }
  /* 项目数据列表 */
  #p4 .defectList {position: absolute; left: 0; top: -50px; width: 1000px; }
  #p4 .defectList .bg-6{ position: absolute; left: 130px; top: 730px; width: 820px; height: 36px; }
  #p4 .defectList .bg-14{ position: absolute; left: 130px; top: 762px; width: 820px; height: 8px; }
  #p4 .defectList .bgs .filter{ filter: grayscale(80%) }
  #p4 .defectList .pageBtns{ position: absolute; left: 140px; top: 705px; width: 800px; text-align: right; }
  #p4 .defectList .pageBtns .pagebtn{ margin: 0 3px }

  #p4 .defectList .pageBtns.numBtns div{ display: inline-block; color: #00FFFF; }

  #p4 .defectList .pageBtns.numBtns div:nth-child(4){
    cursor: pointer;
    transform:rotate(180deg);
    -ms-transform:rotate(180deg); 	/* IE 9 */
    -moz-transform:rotate(180deg); 	/* Firefox */
    -webkit-transform:rotate(180deg); /* Safari 和 Chrome */
    -o-transform:rotate(180deg); 	/* Opera */
  }
  #p4 .defectList .pageBtns.numBtns div:nth-child(4).rotate{ 
    transform:rotate(0deg);
    -ms-transform:rotate(0deg); 	/* IE 9 */
    -moz-transform:rotate(0deg); 	/* Firefox */
    -webkit-transform:rotate(0deg); /* Safari 和 Chrome */
    -o-transform:rotate(0deg); 	/* Opera */
  }
  #p4 .defectList .pageBtns.numBtns div:nth-child(5){ opacity: 0; display: none; position: absolute; left: 110px; top: 20px; width: 680px; border: 1px solid #0135AC; background-color: #041A3F; padding: 5px; z-index: 5; text-align: left; border-radius: 5px; }
  #p4 .defectList .pageBtns.numBtns div:nth-child(5) span{ display: inline-block; width: 30px; height: 30px; line-height: 30px; overflow: hidden; border: 1px solid white; margin: 1px; text-align: center; cursor: pointer; }
  #p4 .defectList .pageBtns.numBtns div:nth-child(5) span:hover{ background-color: #0048FE; }
  #p4 .defectList .pageBtns.numBtns div:nth-child(5) span.current{ background-color: #44991A; color: white; }

  #p4 .defectList .labels { position: absolute; left: 130px; top: 206px; width: 850px; }
  #p4 .defectList .labels .item-5{ position: absolute; left: 22px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p4 .defectList .labels .item-6{ position: absolute; left: 70px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p4 .defectList .labels .item-7{ position: absolute; left: 270px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; width: 120px; text-align: center; }
  #p4 .defectList .labels .item-8{ position: absolute; left: 390px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; width: 120px; text-align: center; }
  #p4 .defectList .labels .item-9{ position: absolute; left: 510px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; width: 120px; text-align: center; }
  #p4 .defectList .labels .item-10{ position: absolute; left: 630px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; width: 120px; text-align: center; }
  #p4 .defectList .labels .item-11{ position: absolute; left: 767px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p4 .defectList .pageList { position: absolute; left: 120px; top:770px; width: 830px; font-size: 15px; height: 450px; overflow: hidden; }
  #p4 .defectList .pageList .row { position: absolute; left: 30px; width: 820px; }
  #p4 .defectList .pageList .row.repaired { color: green }
  #p4 .defectList .pageList .row .bg-line{ position: absolute; left: -22px; top: 0; width: 822px; height: 26px; }
  #p4 .defectList .pageList .row div { font-weight: normal; cursor: pointer; font-family: "microsoft yahei"; }
  #p4 .defectList .pageList .row div:nth-of-type(1){ position: absolute; left: -15px; top: 3px; width: 60px; text-align: center; }
  #p4 .defectList .pageList .row div:nth-of-type(2){ position: absolute; left: 50px; top: 3px; font-family: 'Fjalla One', sans-serif; }
  #p4 .defectList .pageList .row div:nth-of-type(3){ position: absolute; left: 250px; top: 3px; width: 120px; text-align: center; }
  #p4 .defectList .pageList .row div:nth-of-type(4){ position: absolute; left: 370px; top: 3px; width: 120px; text-align: center; }
  #p4 .defectList .pageList .row div:nth-of-type(5){ position: absolute; left: 490px; top: 3px; width: 120px; text-align: center; }
  #p4 .defectList .pageList .row div:nth-of-type(6){ position: absolute; left: 610px; top: 3px; width: 120px; text-align: center; }
  #p4 .defectList .pageList .row div:nth-of-type(7){ position: absolute; left: 730px; top: 3px; width: 60px; text-align: center; }

  #p4 .echarts #chart_p4_01{ position: absolute; left: 145px; top: 280px; width: 375px; height: 145px; /* border: 1px solid red; */ }
  #p4 .echarts #chart_p4_03{ position: absolute; left: 560px; top: 280px; width: 375px; height: 145px; /* border: 1px solid red; */ }
  #p4 .echarts #chart_p4_04{ position: absolute; left: 145px; top: 485px; width: 375px; height: 145px; /* border: 1px solid red; */ }
  #p4 .echarts #chart_p4_02{ position: absolute; left: 560px; top: 485px; width: 375px; height: 145px; /* border: 1px solid red; */ }


</style>

<template>
  <div id="dv-right-tunnel">
    <dv-img class="bg" src="../imgs/img/right/right.json" type="svg" loop="true"></dv-img>
    <i class="time" v-text="currentTime"></i>

    <div class="page" id="p4">
      <dv-img class="bg01" src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"></dv-img>
      <div class="label_trend">发展趋势图</div>
      <dv-img class="bg02" src="imgs/p3/0814aff0-a250-46c9-99d7-565b05d6ab84.png"></dv-img>
      <dv-img class="bg03" src="imgs/p3/0814aff0-a250-46c9-99d7-565b05d6ab84.png"></dv-img>
      <dv-img class="bg04" src="imgs/p3/0814aff0-a250-46c9-99d7-565b05d6ab84.png"></dv-img>
      <dv-img class="bg05" src="imgs/p3/0814aff0-a250-46c9-99d7-565b05d6ab84.png"></dv-img>
      <dv-img class="bg02-title" src="imgs/p3/f7522c50-0cbe-4384-b554-fdec0c1bfde5.png"></dv-img>
      <dv-img class="bg03-title" src="imgs/p3/6707e5e0-5929-4452-b4e5-684c4199da79.png"></dv-img>
      <dv-img class="bg04-title" src="imgs/p3/48ce1d6c-2b7e-427a-bf12-bf5ccf25d2b8.png"></dv-img>
      <dv-img class="bg05-title" src="imgs/p3/f09ce625-c05b-4e73-a318-a0b95a72b81f.png"></dv-img>
      <div class="label_trend_01">衬砌厚度不足问题发展趋势</div>
      <div class="label_trend_02">空洞、不密实问题发展趋势</div>
      <div class="label_trend_03">钢筋缺失问题发展趋势</div>
      <div class="label_trend_04">防裂钢筋网问题发展趋势</div>
      <div class="echarts">
        <div id="chart_p4_01"></div>
        <div id="chart_p4_02"></div>
        <div id="chart_p4_03"></div>
        <div id="chart_p4_04"></div>
      </div>
      <!-- 缺陷数据列表 -->
      <div class="defectList">
        <!-- 缺陷数据列表 -->
        <dv-img class="bg-6" src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"></dv-img>
        <dv-img class="bg-14" src="imgs/p2/line.png"></dv-img>
        <div class="labels">
          <div class="item-5">序号</div>
          <div class="item-6">检测里程</div>
          <div class="item-7">缺陷位置</div>
          <div class="item-8">缺陷长度（M）</div>
          <div class="item-9" v-text="getDefectColumnNames[0]"></div>
          <div class="item-10" v-text="getDefectColumnNames[1]"></div>
          <div class="item-11">备注</div>
        </div>
        <div class="pageBtns" v-if="(Math.floor(defects.length / pagesize) + (defects.length % pagesize > 0 ? 1 : 0)) <= maxPage">  
          <dv-img class="pagebtn" v-for="pageIndex in (Math.floor(defects.length / pagesize) + (defects.length % pagesize > 0 ? 1 : 0))" :key="'page_btn_' + pageIndex" @mouseover="selectPage" :data-index="pageIndex" :title="pageIndex" :src="'imgs/p2/' + (currentPage == pageIndex ? '06f15087-c767-45be-977e-2e00800cfac6' : '2dc0cea5-876d-4e53-9c26-10debca2d21b') + '.png'"></dv-img>
        </div>
        <div class="pageBtns numBtns" v-if="(Math.floor(defects.length / pagesize) + (defects.length % pagesize > 0 ? 1 : 0)) > maxPage">
          <div v-text="currentPage"></div>
          <div>/</div>
          <div v-text="(Math.floor(defects.length / pagesize) + (defects.length % pagesize > 0 ? 1 : 0))"></div>
          <div @click="togglePagePanel">▲</div>
          <div>
            <span v-for="pageIndex in (Math.floor(defects.length / pagesize) + (defects.length % pagesize > 0 ? 1 : 0))" :key="'page_num_' + pageIndex" :class="pageIndex == currentPage ? 'current' : ''" v-text="pageIndex" @click="clickPageNumber" :data-index="pageIndex"></span>
          </div>
        </div>
        <div class="pageList" :style="'height: ' + pagesize * 30 + 'px'" @mouseover="pageMouseOver" @mouseleave="pageMouseLeave">
          <div v-for="(item, index) in defects" :class="'row' + (item.repaired ? ' repaired' : '')" :style="'top: ' + (0 + 30 * (index)) + 'px'" :key="'defectsList_' + index" :title="item.id" @click="clickDefect">
            <dv-img class="bg-line" src="imgs/p5/20200616133308.png"></dv-img>
            <div v-text="index + 1"></div>
            <div v-text="tunnelInfoPatch.code + item.startKiloMetre + '+' + item.startMetre.toFixed(1) + '-' + tunnelInfoPatch.code + (item.startKiloMetre + Math.floor((item.startMetre + item.length) / 1000)) + '+' + ((item.startMetre + item.length) % 1000).toFixed(1)"></div>
            <div v-text="item.position"></div>
            <div v-text="item.length"></div>
            <div v-text="item.info_01"></div>
            <div v-text="item.info_02"></div>
            <div v-html="(item.repaired ? '已整改' : (currentDefect != '混凝土强度' ? item.info_03 : (item.isbug ? '' : '合格')))"></div>
          </div>
          <div class="row" v-for="index in (defects.length < pagesize ? pagesize - defects.length : 0)" :key="'defectsList_bg_' + index" :style="'top: ' + (0 + 30 * (index + defects.length - 1)) + 'px'">
            <dv-img class="bg-line" src="imgs/p5/20200616133308.png"></dv-img>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import * as $ from 'jquery'
import * as echarts from 'echarts'
import dvImg from "./dv-img.vue"
import dataV from '../../bin/dataV'

export default {
  data() {
    return {
      Id: 'RightTunnelComponent',
      currentTime: dataV.DateTime.format('YYYY-mm-dd HH:MM:SS') + '　' + dataV.DateTime.getWeekCH(),
      pagesize: 8,				// 项目、隧道、缺陷列表显示记录数
      currentPage: 1,				// 项目、隧道、缺陷列表当前页码
      currentPageTimer: null,
      contrastIndex: 1, 			// 项目列表 或 隧道列表，对比维度：1-缺陷率对比，2-整改合格率对比
      pageToggleKey: true,		// 是否允许列表页自动翻页
      maxPage: dataV.Config.initPage.maxPage,

      echartsTimer: null,
      charts: {},

      WindowStatus: {
        isPagePanelOpen: false,
      },

      BuildLength: 0,
      CrackLength: 0,
      DesignLength: 0,
      FaultCount1: 0,
      FaultCount2: 0,
      FaultCount3: 0,
      FaultCount4: 0,
      FaultCount5: 0,
      FaultCount6: 0,
      FaultDetailList1: [],
      FaultDetailList2: [],
      FaultDetailList3: [],
      FaultDetailList4: [],
      FaultRate1: 0,
      FaultRate2: 0,
      FaultRate3: 0,
      FaultRate4: 0,
      FaultRate5: 0,
      FaultRate6: 0,
      FaultTrend1: [],
      FaultTrend2: [],
      FaultTrend3: [],
      FaultTrend4: [],
      ModelData: {
        GoodKiloMetreInfo: null,
        OverKiloMetreInfo: {startKiloMetre: 99, startMetre: 482, endKiloMetre: 99, endMetre: 818},
        ProjectDepartment: "湖杭铁路项目经理部二分部",
        ProjectName: "湖杭铁路二分部",
        ProjectType: "铁路",
        SuiDaoDividerInfo: [],
        TunnelAddress: "浙江省富阳市",
        TunnelCode: "DK",
        TunnelEndKiloMetre: 0,
        TunnelEndMetre: 0,
        TunnelName: "羊岩坞二号隧道出口",
        TunnelStartKiloMetre: 0,
        TunnelStartMetre: 0,
        ViewKiloMetreInfo: {startKiloMetre: 0, startMetre: 0, endKiloMetre: 0, endMetre: 0},
        WorkKiloMetreInfo: {startKiloMetre: 0, startMetre: 0, endKiloMetre: 0, endMetre: 0},
        bugs: [],
        checkLines: [],
      },
      Name: "",
      PileNumCode: "",
      ProjectSimpleInfo: {BelongCategory: "", SiteName: "", Address: ""},
      TestLength: 0,
      TestRate: 0,
      TotalFaultBadCount: 0,
      TotalFaultBadFixRate: 0,
      TotalFaultBadRate: 0,
      TotalFaultCount: 0,
      TotalFaultFixRate: 0,
      TotalFaultRate: 0,

      currentDefect: dataV.Config.initPage.defect,

    }
  },
  props: [],
  components: {
    "dv-img": dvImg,
  },
  methods: {

    clickDefect(){
      var defectID = $(event.target).offsetParent().attr('title');
      if(dataV.WebVR) dataV.WebVR.lookAtDefect(defectID);
    },

    clickPageNumber(){
      this.selectPage();
      this.togglePagePanel();
    },

    selectPage(){		// 数据列表，手动换页
      this.currentPage = $(event.target).data('index');
      this.changePage(this.defects.length);
    },

    togglePagePanel(){
      var $pageBtn = $('#p4 .defectList .pageBtns.numBtns div:nth-child(4)');
      var $pagePanel = $('#p4 .defectList .pageBtns.numBtns div:nth-child(5)');
      if(!this.WindowStatus.isPagePanelOpen){
        this.WindowStatus.isPagePanelOpen = true;
        $pageBtn.addClass('rotate');
        $pagePanel.css({
          opacity: 0
        }).show().animate({
          opacity: 1
        }, 500)
      }else{
        this.WindowStatus.isPagePanelOpen = false;
        $pageBtn.removeClass('rotate');
        $pagePanel.animate({
          opacity: 0
        }, 500, function(){
          $pagePanel.hide()
        })
      }
    },


    changePage(dataLength){
      var that = this;
      //
      if(this.currentPageTimer) clearInterval(this.currentPageTimer);
      var defectPageCount = Math.floor(dataLength / this.pagesize) + (dataLength % this.pagesize > 0 ? 1 : 0);
      if(defectPageCount > 1) {
        if(this.currentPageTimer) {
          clearInterval(this.currentPageTimer)
          this.currentPageTimer = null
        }
        this.currentPageTimer = setInterval(function(){
          if(that.pageToggleKey){
            var newpage = that.currentPage + 1;
            if(newpage > defectPageCount) newpage = 1;
            that.currentPage = newpage;
          }
        }, 5000);
      }
    },

    toggleContrastBtn(){
      this.contrastIndex = $(event.target).data('index'); 
    },
    pageMouseOver(){
      this.pageToggleKey = false;
    },
    pageMouseLeave(){
      this.pageToggleKey = true;
    },

    p2trend(){
      var that = this;
      //      
      if(!this.charts['chart_011']) this.charts['chart_011'] = echarts.init(document.getElementById('chart_p4_01'));
      this.charts['chart_011'].setOption({
          tooltip: { trigger: 'axis' }, grid: { left: '30', right: '0', bottom: '20', top: '10', },
          xAxis: { show: true, type: 'category', boundaryGap: false, data: that.FaultTrend1.map(item => item.Name) },
          yAxis: { show: true, type: 'value', splitLine: { show: false } },
          series: [
              { name: '衬砌厚度不足', type: 'line', data: that.FaultTrend1.map(item => item.FaultCount), smooth: true, showSymbol: false, hoverAnimation: false, lineStyle: { color: "#b30306", width: 1 }, areaStyle: { color: "#b30306", opacity: 0.3 } }
          ]
      });
      if(!this.charts['chart_022']) this.charts['chart_022'] = echarts.init(document.getElementById('chart_p4_02'));
      this.charts['chart_022'].setOption({
          tooltip: { trigger: 'axis' }, grid: { left: '30', right: '0', bottom: '20', top: '10', },
          xAxis: { show: true, type: 'category', boundaryGap: false, data: that.FaultTrend2.map(item => item.Name) },
          yAxis: { show: true, type: 'value', splitLine: { show: false } },
          series: [
              { name: '空洞、不密实', type: 'line', data: that.FaultTrend4.map(item => item.FaultCount), smooth: true, showSymbol: false, hoverAnimation: false, lineStyle: { color: "#33CBE9", width: 1 }, areaStyle: { color: "#33CBE9", opacity: 0.3 } }
          ]
      });
      if(!this.charts['chart_033']) this.charts['chart_033'] = echarts.init(document.getElementById('chart_p4_03'));
      this.charts['chart_033'].setOption({
          tooltip: { trigger: 'axis' }, grid: { left: '30', right: '0', bottom: '20', top: '10', },
          xAxis: { show: true, type: 'category', boundaryGap: false, data: that.FaultTrend3.map(item => item.Name) },
          yAxis: { show: true, type: 'value', splitLine: { show: false } },
          series: [
              { name: '钢筋缺失问题', type: 'line', data: that.FaultTrend2.map(item => item.FaultCount), smooth: true, showSymbol: false, hoverAnimation: false, lineStyle: { color: "#FFBC09", width: 1 }, areaStyle: { color: "#FFBC09", opacity: 0.3 } }
          ]
      });
      if(!this.charts['chart_044']) this.charts['chart_044'] = echarts.init(document.getElementById('chart_p4_04'));
      this.charts['chart_044'].setOption({
          tooltip: { trigger: 'axis' }, grid: { left: '30', right: '0', bottom: '20', top: '10', },
          xAxis: { show: true, type: 'category', boundaryGap: false, data: that.FaultTrend4.map(item => item.Name) },
          yAxis: { show: true, type: 'value', splitLine: { show: false } },
          series: [
              { name: '防裂钢筋网问题', type: 'line', data: that.FaultTrend3.map(item => item.FaultCount), smooth: true, showSymbol: false, hoverAnimation: false, lineStyle: { color: "#ffffff", width: 1 }, areaStyle: { color: "#ffffff", opacity: 0.3 } }
          ]
      });

      // 轮循tooltip
      if(this.echartsTimer) clearInterval(this.echartsTimer)
      var index = 0; //播放所在下标
      this.echartsTimer = setInterval(function() {
          that.charts['chart_011'].dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: index });
          that.charts['chart_022'].dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: index });
          that.charts['chart_033'].dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: index });
          that.charts['chart_044'].dispatchAction({ type: 'showTip', seriesIndex: 0, dataIndex: index });
          index++;
          if(index >= that.FaultTrend4.length) {
              index = 0;
          }
      }, 3000);

    },

  },
  computed: {
    getDefectColumnNames(){
      var rv = null
      switch(this.currentDefect){
        case '衬砌厚度不足':
          rv = ['设计厚度（CM）', '实测厚度（CM）'];
          break;
        case '钢筋缺失问题':
          rv = ['设计数量（根）', '实测数量（根）'];
          break;
        case '其他问题':
          rv = ['缺陷类型', '距表面深度（CM）']
          break;
        case '防裂钢筋网问题':
          rv = ['设计值', '实测值']
          break;
        default:
          rv = ['设计值', '实测值']
      }
      return rv
    },
    
    tunnelInfoPatch(){
      // console.log('hehe: ', this.ModelData.TunnelStartMetre)
      return {
        id: '',
        name: this.ModelData.TunnelName,									// 名称
        address: this.ModelData.TunnelAddress,								// 地点
        code: this.ModelData.TunnelCode,
        startKM: this.ModelData.TunnelStartKiloMetre,
        startM: this.ModelData.TunnelStartMetre,
        endKM: this.ModelData.TunnelEndKiloMetre,
        endM: this.ModelData.TunnelEndMetre,
        planLength: this.DesignLength,
        buildLength: this.BuildLength,
        checkLength: this.TestLength,
        betonLength: this.CrackLength,
        explains: '',
        defects: this.ModelData.bugs,
        checkLines: this.ModelData.checkLines,
        suiDaoDividerInfo: this.ModelData.SuiDaoDividerInfo
      }
    },

    defects(){
      // console.log('rightTunnel', this.currentDefect)
      return this.tunnelInfoPatch.defects.filter((item)=>{ return item.type == this.currentDefect })
    },

  },
  watch: {
    
    currentPage(){
      var targetTop = (this.currentPage - 1) * this.pagesize * 30;
      $('#p4 .defectList .pageList').animate({
        scrollTop: targetTop + 'px'
      }, 'slow');
    },

    TunnelCompareFault(){
      this.changePage(this.defects.length);
    },

    FaultTrend1() {
      this.p2trend()
    }

  },
  created() {
			window[this.Id] =  this;
  },
  mounted() {
  },
};
</script>
