import dataV from '../bin/dataV'

const apiBaseUrl = dataV.Config.baseUrl.api;

const apiUrls = {
    token: '/App/GetScreenToken',
    index: '/App/GetScreenIndexData',
    program: '/App/GetScreenProjectData',                   // projectInfoId
    tunnel: '/App/GetScreenTunnelData',                     // tunnelInfoId
    programContrast: '/App/GetScreenProjectCompareData',    // projectInfoIds（项目编号，用英文逗号隔开）
    tunnelContrast: '/App/GetScreenTunnelCompareData',      // tunnelInfoIds（隧道编号，用英文逗号隔开，全部隧道对比传递all）
    checkOver: '/App/GetScreenCheckOverData',               // tempId（数据编号）、datatype（1：项目、2：隧道）
    fixOver: '/App/GetScreenFixOverData',                   // tempId（数据编号）、datatype（1：项目、2：隧道）
}

const Query = require('querystring');

let api = {
    install: function(Vue){
        Vue.prototype.$api = this;
    },
    request: ({ apiName, type, query, data, options, Vue }) => {
        const apiUrl = apiBaseUrl + apiUrls[apiName] + (query ? '?' + (typeof(query) == 'object' ? Query.stringify(query) : query) : '');
        type = type || 'post';
        console.log(type, apiUrl);
        return type ? Vue.$http.get(apiUrl, data, options) : Vue.$http.post(apiUrl, data, options)
    }
}

export default api