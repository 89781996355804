import _Vue from 'vue'
import App from './App.vue'
import VueAxiosPlugin from 'vue-axios-plugin'
import api from "./api/index";
import * as $ from 'jquery'
import vuescroll from 'vuescroll'
import 'vuescroll/dist/vuescroll.css'
import jsCookie from 'js-cookie'

window.ApiData = {};

_Vue.config.productionTip = false

_Vue.use(VueAxiosPlugin, {
  reqHandleFunc: config => {
    config.headers['X-Token'] = window.ApiData.token || Vue.$cookie.get('token')
    return config
  },
  reqErrorFunc: error => Promise.reject(error),
  resHandleFunc: response => response.data,
  resErrorFunc: error => {
    $('#app').html('服务器响应失败，请联系管理员！');
    Promise.reject(error)
  }
}).use(api).use(vuescroll)

_Vue.prototype.$cookie = jsCookie

const url = require('url');
const query = require('querystring');
const { code } = query.parse(url.parse(window.location.href).query);
let tokenParam = query.parse(url.parse(window.location.href).query);
const Vue = new _Vue({ render: h => h(App) })
const loadVueUI = () => { Vue.$mount('#app') }
const Funs = [
  { key: 'token', apiName: 'token', query: { randomCode: code || '' } },
  { key: 'index', apiName: 'index' },
  // { apiName: 'program' },
]

let FunIndex = -1;
let ApiErr = false;
let AppLoop = false;

const getApiDate = (startIndex) => {
  if (!Funs[startIndex || (FunIndex + 1)]) {
    console.log(window.ApiData)
    if (!ApiErr) loadVueUI()
    else {
      if (!AppLoop) {
        FunIndex = -1
        AppLoop = true
        ApiErr = false
        getApiDate()
      } else $('#app').html('<div style="font-size: 18px">[' + new Date().toLocaleString() + '] 错误 => <span>本次授权信息已失效，请重新获取有效的访问码</span></div>');
    }
    return;
  }
  FunIndex = startIndex || (FunIndex + 1)
  const params = Funs[FunIndex]
  $('#app').append('<div id="' + params.apiName + '" style="font-size: 18px">[' + new Date().toLocaleString() + '] 获取 ' + params.apiName + ' => <span></span></div>');
  Vue.$api.request(Object.assign({ Vue }, params)).then(response => {
    if (response.state === 'success') {
      $('#app #' + params.apiName + ' span').html('成功')
      if (params.cb) params.cb(response.data)
    } else {
      $('#app #' + params.apiName + ' span').html('失败：' + response.message)
      ApiErr = true
    }
    window.ApiData[params.key] = (() => { try { return JSON.parse(response.data) } catch (e) { return response.data } })()

    // 将token保留到本地cookie中
    if (params.key == 'token') Vue.$cookie.set('token', window.ApiData[params.key])

    getApiDate()
  })
}

if (tokenParam && tokenParam.token) {
  $('#app').append('<div style="font-size: 18px">[' + new Date().toLocaleString() + '] 检测授权信息 => <span>' + tokenParam.token + '</span></div>');
  Vue.$cookie.set('token', tokenParam.token)
  getApiDate(1)
} else {
  const localToken = Vue.$cookie.get('token')
  $('#app').append('<div style="font-size: 18px">[' + new Date().toLocaleString() + '] 检测授权信息 => <span>' + localToken + '</span></div>');
  if (!localToken) getApiDate()
  else getApiDate(1)
}