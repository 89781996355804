<style>
#dv-left-index { position: absolute; left: -1100px; top: 0; width: 1000px; }
#dv-left-index .bg { position: absolute; left: 0; top: 20px; width: 100%; }

#section_caption { position: absolute; left: 75px; top: 130px; font-size: 27px; font-weight: 550; }
#logo { position: absolute; left: 70px; top: 199px; }
#info-back { position: absolute; left: 210px; top: 199px; }
#section_title { position: absolute; left: 230px; top: 210px; font-size: 16px; font-weight: bold; }
#section_description { position: absolute; left: 230px; top: 240px; text-align: left; width: 640px; line-height: 160%; height: 75px; overflow: hidden; }
#videos_back { position: absolute; left: 70px; top: 355px; }
#videos_title { position: absolute; left: 88px; top: 362px; font-size: 16px; font-weight: bold; }

.videos{ position: absolute; left: 70px; top: 350px; width: 331px; }
.videos .text{ position: absolute; left: 17px; top: 6px; font-size: 16px; }
.videos .videoBox{ position: absolute; left: 9px; top: 40px; width: 313px; height: 176px; border: 1px solid #0055AA; text-align: center; line-height: 176px; color: #0055AA; }
.videos .video-0-bg{ position: absolute; left: 156px; top: 88px; width: 0; height: 0; }
.videos .video-0{ position: absolute; left: 0; top: 0; width: 313px; height: 176px; }
.videos .video-1{ position: absolute; left: 9px; top: 234px; width: 75px; height: 51px; }
.videos .video-2{ position: absolute; left: 89px; top: 234px; width: 75px; height: 51px; }
.videos .video-3{ position: absolute; left: 169px; top: 234px; width: 75px; height: 51px; }
.videos .video-4{ position: absolute; left: 249px; top: 234px; width: 75px; height: 51px; }
.videos .video-5{ position: absolute; left: 9px; top: 289px; width: 75px; height: 51px; }
.videos .video-6{ position: absolute; left: 89px; top: 289px; width: 75px; height: 51px; }
.videos .video-7{ position: absolute; left: 169px; top: 289px; width: 75px; height: 51px; }
.videos .video-8{ position: absolute; left: 249px; top: 289px; width: 75px; height: 51px; }

.honours{ position: absolute; left: 70px; top: 706px; width: 331px; }
.honours .text{ position: absolute; left: 0; top: 0; }
.honours .text div{ position: absolute; left: 0; width: 334px; text-align: center; }
.honours .text div.topic{ top: 24px; }
.honours .bg-view-honours{ position: absolute; left: 160px; top: 100px; width: 10px; height: 10px; z-index: 100; overflow: hidden; display: none; }
.honours .bg-view-honours .honour-view-bg{ position: absolute; left: 0; top: 0; }
.honours .bg-view-honours .honour-view-img{ position: absolute; left: 5px; top: 63px; width: 626px; height: 862px }
.honours .bg-view-honours .honour-view-title{ position: absolute; left: 14px; top: 32px; width: 635px; font-size: 16px; font-weight: bold; }
.honours .bg-view-honours .small-list{ position: absolute; left: 638px; top: 0; width: 150px; text-align: center; }
.honours .bg-view-honours .small-list .small-list-pages span{ display: inline-block; border: 1px solid white; height: 20px; line-height: 20px; overflow: hidden; border-radius: 0; }
.honours .bg-view-honours .small-list .small-list-pages span:nth-child(1),
.honours .bg-view-honours .small-list .small-list-pages span:nth-child(3){ width: 20px; cursor: pointer; }
.honours .bg-view-honours .small-list .small-list-pages span:nth-child(2){ width: 90px }
.honours .bg-view-honours .small-list .small-list-imgs{ width: 100%; height: 908px; overflow: hidden; background-color: #0546E9; }
.honours .bg-view-honours .small-list .small-list-imgs img{ width: 60px; border-width: 0; }
.honours .bg-view-honours .small-list .small-list-imgs img.current{ width: 80px; border: 1px solid #01F2F5; }

.resources{ position: absolute; left: 414px; top: 350px; width: 470px; }
.resources .bg-resources{ position: absolute; left: 0; top: 0; }
.resources .item-54{ position: absolute; left: 30px; top: 15px; width: 160px; height: 30px; font-size: 14px; font-weight: normal; text-align: center; }
.resources .preset01{ position: absolute; right: 10px; top: 10px; width: 220px; }
.resources .preset01-qrcode{ position: absolute; left: 30px; top: 45px; width: 160px; z-index: 100; }
.resources .preset_pages{ position: absolute; left: 30px; top: 215px; width: 160px; text-align: center; }
.resources .preset_pages .pageIcon{ display: inline-block; margin: 0 2px; width: 8px; cursor: pointer; }
.resources .explainbox01{ position: absolute; left: 25px; top: 250px; font-size: 14px; font-weight: normal; width: 430px; height: 240px; line-height: 150%; }
.resources .explainbox01 .item{ float: left; width: 100%; margin-bottom: 7px; }
.resources .explainbox01 .item .name{ float: left; width: 200px; font-size: 12px; color: #47F9FF; margin-top: 5px; }
.resources .explainbox01 .item .explain{ float: left; width: 100%; margin-top: 0; font-size: 12px; line-height: 120%; }

</style>

<template>
  <div id="dv-left-index">
    <div>
      <dv-img class="bg" src="./imgs/img/left/left.json" type="svg" loop="true"></dv-img>
      <div id="section_caption" v-text="AreaName + '介绍'"></div>
      <dv-img id="logo" src="./imgs/logo-old.png"></dv-img>
      <dv-img id="info-back" src="./imgs/p1/text.png"></dv-img>
      <div id="section_title">项目介绍</div>
      <div id="section_description" v-html="AreaDescription"></div>
    </div>
    <div class="videos">
      <dv-img class="bg-videos" src="imgs/p1/kuang-1.png"></dv-img>
      <div class="text">场地视频</div>
      <div class="videoBox">
        <dv-img id="video-0-bg" class="video-0-bg" src="imgs/p1/76126803-86ed-4e94-9f3a-589f5cb608e8.png"></dv-img>
        <video id="video-0" class="video-0" autoplay="autoplay" loop="loop" muted="muted" :src="currentVideo" @click="scaleVideo"></video>
        视频已放大，点击视频恢复
      </div>
      <dv-img v-for="(video, index) in VideoList.slice(0, 8)" :key="index" :class="'video-' + (index + 1) + ' videoImg'" :src="ImgBaseUrl + video.CoverLink" :data-src="video.OssUrl" @click="toggleVideo"></dv-img>
    </div>
    <div class="honours">
      <dv-img class="bg-honours" src="imgs/p1/kuang-2.png"></dv-img>
      <div class="text">
        <div class="topic">获奖证书</div>
      </div>
			<dv-img-rotator id="centerHonours" width="331" height="220" delay="3000" :data="centerHonours" @click="enlargeHonour"></dv-img-rotator>
      <div class="bg-view-honours">
        <dv-img class="honour-view-bg" src="imgs/honours/证书背景.png"></dv-img>
        <dv-img class="honour-view-img" :src="honourImg.src" @click="narrowHonour"></dv-img>
        <div class="honour-view-title" v-text="honourImg.title"></div>
        <div class="small-list">
          <div class="small-list-pages">
            <span @click="toggleHonourInfo(0, 'prev')">&lt;</span>
            <span v-text="honourImg.index + ' / ' + honourImg.count"></span>
            <span @click="toggleHonourInfo(0, 'next')">&gt;</span>
          </div>
          <div class="small-list-imgs">
            <div v-for="(item, index) in centerHonours" :key="'honour_small_img_' + index"><img :class="index == honourImg.index - 1 ? 'current' : ''" :src="item.img" @click="toggleHonourInfo(index)"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="resources">
      <dv-img class="bg-resources" src="imgs/p1/kuang-3.png"></dv-img>
      <dv-img class="bg-resources" src="imgs/p101/bg001.png"></dv-img>
      <div class="bgs">        
        <div class="item-54" v-text="PresetParts[currentPreset].title"></div>
        <!-- 预制件 -->
        <dv-img class="preset01" src="imgs/p11/4cae0ce4-e089-4876-bdac-f3253c0cd87e.png"></dv-img>
        <!-- <dv-img class="preset02" src="imgs/p11/ce817a11-c777-4860-b30f-30f4e9f60b6b.png"></dv-img> -->
        <dv-img class="preset01-qrcode" :src="'imgs/检测基地 2/预制件二维码/' + PresetParts[currentPreset].title + '.png'"></dv-img>
        <!-- <dv-img class="preset02-qrcode" src="imgs/p11/b4328e4b-0cf2-4ef2-93ab-25615ac45992.jpeg"></dv-img> -->
        <div class="preset_pages">
          <dv-img class="pageIcon" v-for="(item, index) in PresetParts" :key="'pageIcon_' + index" :title="index" @mouseover="togglePreset" :src="'imgs/p2/' + (currentPreset == index ? '06f15087-c767-45be-977e-2e00800cfac6' : '2dc0cea5-876d-4e53-9c26-10debca2d21b') + '.png'" :data-index="index"></dv-img>
        </div>
        <div class="explainbox01">
          <div class="item" v-for="(item, index) in PresetParts[currentPreset].defects" :key="'explainitem_' + index">
            <div class="name" v-text="item.name"></div>
            <div class="explain" v-text="item.explain"></div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import * as $ from 'jquery';
import dataV from '../../bin/dataV';
import dvImg from "./dv-img.vue";
import dvImgRotator from "./dv-img-rotator.vue";

export default {
  data() {
    return {
      Id: 'LeftIndexComponent',

      AreaName: dataV.Config.AreaName,
      AreaDescription: dataV.Config.AreaDescription,
      ImgBaseUrl: dataV.Config.baseUrl.img,
      VideoList: window.ApiData.index.VideoList,
      currentVideo: window.ApiData.index.VideoList.length > 0 ? window.ApiData.index.VideoList[0].OssUrl : '',
      centerHonours: window.ApiData.index.HonorBookList.map(item => {return { name: item.Name, content: item.BelongCategory, img: dataV.Config.baseUrl.img + item.PicUploadUrl }}),

      currentPreset: 0,
      PresetParts: dataV.Config.PresetParts,

      currentPageLevel: dataV.Config.initPage.level,
      currentArea: dataV.Config.AreaName,
      currentProgram: dataV.Config.initPage.program,
      currentTunnel: dataV.Config.initPage.tunnel,
      currentTunnelID: dataV.Config.initPage.tunnelId,
      currentDefect: dataV.Config.initPage.defect,

      honourImg: {      // 荣誉证书放大后的信息
        src: '',
        title: '',
        index: 0,
        count: 0,
      },

      WindowStatus: {
        isCompanyVideoOpen: false,
        isHonourInfoOpen: false,
      },
            
    };
  },
  props: [],
  methods: {

    // 手动方式，设置当前模块的语音声控指令，已达到手动控制的效果
    scaleVideo(){
      if($(event.target).width() < 400){
        this.enlargeVideo();
      }else{
        this.narrowVideo();
      }
    },

    // 放大视频
    enlargeVideo(){
      dataV.ModelWindows.close('全国视频');
      this.WindowStatus.isCompanyVideoOpen = true;

      var el = document.getElementById('video-0');
      $('#video-0-bg').css({'z-index': dataV.Config.zIndex.video - 1}).animate({
        left: '1247px',
        top: '-237px',
        width: '1148px',
        height: '774px',
      }, 500);
      $(el).css({'z-index': dataV.Config.zIndex.video}).animate({
        left: '1253px',
        top: '-171px',
        width: '1136px',
        height: '639px',
      }, 500, function(){
        el.muted = false;
        el.loop = false;
        el.currentTime = 0;
      });
    },

    // 缩小视频
    narrowVideo(){
      // 缩小全国区域视频
      if(this.WindowStatus.isCompanyVideoOpen){
        this.WindowStatus.isCompanyVideoOpen = false;
        var el = document.getElementById('video-0');
        el.muted = true;
        el.loop = true;
        $('#video-0-bg').animate({
          left: '156px',
          top: '88px',
          width: 0,
          height: 0,
        }, 500);
        $(el).animate({
          left: 0,
          top: 0,
          width: '313px',
          height: '176px'
        }, 500);
      }
        
      // 缩小项目部视频
      if(this.WindowStatus.isProgramVideoOpen){
        this.WindowStatus.isProgramVideoOpen = false;
        var el2 = document.getElementById('video-10');
        el2.muted = true;
        el2.loop = true;
        $('#video-10-bg').animate({
          left: '156px',
          top: '88px',
          width: 0,
          height: 0,
        }, 500);
        $(el2).animate({
          left: 0,
          top: 0,
          width: '280px',
          height: '158px'
        }, 500);
      }
    },
    
    // 切换视频
    toggleVideo () {
      var $v = $(event.target);
      this.currentVideo = $v.data('src');
    },

    // 放大证书
    enlargeHonour(){
      var that = this;
      //
      if(that.currentPageLevel == 1){
        dataV.ModelWindows.close('荣誉证书');
        that.WindowStatus.isHonourInfoOpen = true;

        // 获取轮盘动画中的证书信息
        console.log(window.HonourViewer)
        this.honourImg.src = window.HonourViewer.img.src;
        this.honourImg.title = window.HonourViewer.img.title;
        this.honourImg.index = window.HonourViewer.imgs.current;
        this.honourImg.count = window.HonourViewer.imgs.length;

        // that.sound4fly.run(true);
        var $el = $('.honours .bg-view-honours');
        $el.css({
          left: '160px',
          top: '100px',
          width: '10px',
          height: '10px',
        }).show().animate({
          left: '1510px',
          top: '-610px',
          width: (645 + 152) + 'px',
          height: '932px',
        }, 500, function(){
          that.honourViewListAction();
        });
      }				
    },
    
    // 关闭证书
    narrowHonour(){
      this.WindowStatus.isHonourInfoOpen = false;
      //
      // that.sound4close.run(true);
      var $el = $('.honours .bg-view-honours');
      $el.animate({
        left: '1830px',
        top: '-165px',
        width: 0,
        height: 0,
      }, 500, function(){
        $el.hide()
      });
    },
    
    // 切换证书浏览器中的证书信息
    toggleHonourInfo(index, toggle){
      if(toggle == 'prev'){
        index = this.honourImg.index - 2;
        if(index < 0) index = this.centerHonours.length - 1;
      }else if(toggle == 'next'){
        index = this.honourImg.index;
        if(index >= this.centerHonours.length) index = 0;
      }

      this.honourImg.src = this.centerHonours[index].img;
      this.honourImg.title = this.centerHonours[index].name;
      this.honourImg.index = index + 1;

      this.honourViewListAction();
    },

    // 证书浏览器右侧图片列表响应
    honourViewListAction(){
      var that = this;
      //
      var imgHeight = 90;	
      var $imgFrame = $('#dv-left #p_Left_1 .honours .bg-view-honours .small-list .small-list-imgs');
      var frameHeight = $imgFrame.height();
      var max = Math.floor(frameHeight / imgHeight);
      if(that.honourImg.index > (max - 1)){
        $imgFrame.scrollTop((that.honourImg.index - (max - 1)) * imgHeight);
      }else{
        $imgFrame.scrollTop(0);
      }
    },

    // 切换预置件
    togglePreset(type){
      if(type == 'prev'){
        var _page = this.currentPreset - 1;
        if(_page < 0) _page = this.baseinfo.presetparts.length - 1;
        this.currentPreset = _page;
      }else if(type == 'next'){
        _page = this.currentPreset + 1;
        if(_page >= this.baseinfo.presetparts.length) _page = 0;
        this.currentPreset = _page;
      }else{
        var $this = $(event.target);
        this.currentPreset = $this.data('index');
      }
    },
      
  },
  created() {
			window[this.Id] =  this;
  },
  mounted() {
    // 注册弹出窗口
    dataV.ModelWindows.regist('全国视频', this.WindowStatus, 'isCompanyVideoOpen', true, this.narrowVideo);
    dataV.ModelWindows.regist('荣誉证书', this.WindowStatus, 'isHonourInfoOpen', true, this.narrowHonour);
  },
  components: {
    "dv-img": dvImg,
    "dv-img-rotator": dvImgRotator,
  },
};
</script>
