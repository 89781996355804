<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #ffffff;
  font-size: 12px;
}
</style>

<template>
  <div id="app">
    <dv-map />
    <dv-header />
    <dv-footer />

    <dv-left-flow />
    <dv-left-index />
    <dv-left-program />
    <dv-left-tunnel />

    <dv-right-index />
    <dv-right-program />
    <dv-right-tunnel />

    <dv-right-menu />
    <dv-right-list />

    <dv-wins />
  </div>
</template>

<script>
import dataV from "./bin/dataV"

import dvMap from "./components/page/dv-map.vue"
import dvHeader from "./components/page/dv-header.vue"
import dvFooter from "./components/page/dv-footer.vue"

import dvLeftFlow from "./components/page/dv-left-flow.vue"
import dvLeftIndex from "./components/page/dv-left-index.vue"
import dvLeftProgram from "./components/page/dv-left-program.vue"
import dvLeftTunnel from "./components/page/dv-left-tunnel.vue"

import dvRightIndex from "./components/page/dv-right-index.vue"
import dvRightProgram from "./components/page/dv-right-program.vue"
import dvRightTunnel from "./components/page/dv-right-tunnel.vue"

import dvRightMenu from "./components/page/dv-right-menu.vue"
import dvRightList from "./components/page/dv-right-list.vue"

import dvWindows from './components/page/dv-wins'

export default {
  name: "App",
  components: {
    // "dv-img": dvImg,
    "dv-map": dvMap,
    "dv-header": dvHeader,
    "dv-footer": dvFooter,

    "dv-left-flow": dvLeftFlow,
    "dv-left-index": dvLeftIndex,
    "dv-left-program": dvLeftProgram,
    "dv-left-tunnel": dvLeftTunnel,

    "dv-right-index": dvRightIndex,
    "dv-right-program": dvRightProgram,
    "dv-right-tunnel": dvRightTunnel,

    "dv-right-menu": dvRightMenu,
    "dv-right-list": dvRightList,

    'dv-wins': dvWindows,
  },
  data() {
    return {}
  },
  created() { },
  mounted() {
    dataV.Page.Toggle(this, 1)
    // dataV.Page.Toggle(this, 2, '953dc1a8-e97e-4957-b814-34c482bbd0f4')
    // dataV.Page.Toggle(this, 3, 'e8414c5a-f7e5-4733-b00b-3df26d09ff7c')

  }
}
</script>