<style>
	#dv-right-list{
		position: absolute;
		left: 2600px;
		top: 370px;
		width: 250px;
	}
	#dv-right-list .title{
		position: absolute;
		left: 0;
		top: 0;
		font-size: 24px;
	}
	#dv-right-list .title span{
		color: #45edff;
		/* font-family: 'Work Sans', sans-serif; */
		/* font-weight: bold; */
		font-size: 24px;
	}
	#dv-right-list .btns{
		position: absolute;
		left: 0;
		top: 45px;
		width: 300px;
	}
	#dv-right-list .btns img {
		position: absolute;
		left: 0;
		top: 0;
		width: 120px;
		height: 40px;
		cursor: pointer;
	}
	#dv-right-list .btns img:nth-child(2) { left: 130px }
	#dv-right-list .btns div {
		position: absolute;
		left: 0;
		top: 0;
		width: 120px;
		color: #87bafd;
		font-size: 16px;
		font-weight: bold;
		line-height: 40px;
		text-align: center;
		pointer-events: none;
	}
	#dv-right-list .btns .btn02 { left: 130px }

	#dv-right-list .list{
		position: absolute;
		left: 0;
		top: 90px;
		font-size: 20px;
		line-height: 200%;
		text-align: left;
	}
	#dv-right-list i {
		font-style: normal;
		cursor: pointer;
		margin-left: 10px;
	}
	#dv-right-list i.selected{
		color: #00FFF8;
	}

</style>

<template>
	<div id="dv-right-list" :style="currentPageLevel == 3 ? 'display: none' : ''">
		<div class="title" @click="selected(1)"><i v-text="currentArea"></i><span v-if="ProjectList.length>0" v-text="'（' + ProjectList.length + '）'"></span></div>
		<div class="btns">
			<dv-img @click="ComparePrograms" src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"></dv-img>
			<dv-img @click="CompareTunnels" src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"></dv-img>
			<div class="btn01">全部项目对比</div>
			<div class="btn02">全部隧道对比</div>
		</div>
		<div class="list">
			<div v-for="(item, index) in ProjectList" :key="index">
				<span><dv-img class="icon" src="imgs/icon.png"></dv-img></span>
				<i @click="selected(2, item.Id)" :class="(currentProgram == item.Id) ? 'selected' : ''" v-text="item.Name"></i>
			</div>
		</div>
	</div>
</template>

<script>
	// import * as $ from 'jquery'
	import dataV from '../../bin/dataV'
	import dvImg from './dv-img'

	export default {
		data: function(){
			return {	
				Id: 'RightListComponent',

				ProjectList: window.ApiData.index.ProjectList,

				currentPageLevel: dataV.Config.initPage.level,		// 大屏当前页面层级，1-全国、2-项目部
				currentArea: dataV.Config.AreaName,
				currentProgram: dataV.Page.CurrentId,
				currentTunnel: dataV.Config.initPage.tunnel,
				currentDefect: dataV.Config.initPage.defect,
				
				programCount: 0,
				programs: [],
				selectPrograme: -1,
			}
		},
		props: ['title'],
		created() {
			window[this.Id] =  this;
		},
		methods: {
			ComparePrograms(all){
				this.$api.request({ Vue: this, apiName: 'programContrast', query: { projectInfoIds: all || 'all' } }).then(response => {
					if (response.state === 'success') {
						const json = JSON.parse(response.data)
						window.CenterWindowsComponent.set('programs', json)
					} else {
						alert(response.message)
					}
				})
			},
			CompareTunnels(all){
				console.log(all)
				this.$api.request({ Vue: this, apiName: 'tunnelContrast', query: { tunnelInfoIds: all || 'all' } }).then(response => {
					if (response.state === 'success') {
						const json = JSON.parse(response.data)
						window.CenterWindowsComponent.set('tunnels', json)
					} else {
						alert(response.message)
					}
				})
			},

			toggle(){
			},

			sync(){
				this.currentPageLevel = dataV.Page.CurrentLevel
				this.currentProgram = dataV.Page.CurrentId
			},
			
			selected(level, id){
				dataV.Page.Toggle(this, level, id)
				// // 跟随按钮，切换地图右侧的项目文字列表
				// var dvRightMenu = this.$parent.$children.filter((item) => { return item.$el.id == 'dv-right-menu' })[0];
				// // console.log(isNumber(num), num)
				// if(isNumber(num)){
				// 	if(num == -1){
				// 		var newNum = this.selectPrograme - 1;
				// // console.log(2, newNum)
				// 		if(newNum < 0) newNum = 0;
				// 		if(this.programs[newNum] && newNum != this.selectPrograme){
				// 			this.selectPrograme = newNum;
				// 			dvRightMenu.animate(2, null, this.programs[newNum]);
				// 		}
				// 	}else if(num == -2){
				// 		newNum = this.selectPrograme + 1;
				// // console.log(3, newNum)
				// 		if(newNum >= this.programs.length) newNum = this.programs.length - 1;
				// 		if(this.programs[newNum] && newNum != this.selectPrograme){
				// 			this.selectPrograme = newNum;
				// 			dvRightMenu.animate(2, null, this.programs[newNum]);
				// 		}
				// 	}else if(num == -3){
				// 		newNum = this.programs.length - 1;
				// // console.log(4, newNum)
				// 		if(this.programs[newNum]){
				// 			this.selectPrograme = newNum;
				// 			dvRightMenu.animate(2, null, this.programs[newNum]);
				// 		}
				// 	}else{
				// // console.log(5, num-1)
				// 		if(this.programs[num-1]){
				// 			this.selectPrograme = num-1;
				// 			dvRightMenu.animate(2, null, this.programs[num-1]);
				// 		}
				// 	}
				// }else{
				// 	dvRightMenu.animate(2, null, $(event.target).text());
				// }

				// function isNumber(obj) {  
				// 	return typeof obj === 'number' && !isNaN(obj)  
				// }
			}
		},
		watch: {
			currentArea(){
				this.toggle();
			}
		},
		mounted: function(){
			// 加载api数据

			window.RightListComponent =  this;
			
			this.toggle();

		},
		components: {
			'dv-img': dvImg
		}
		
	}
</script>
