<style>
  #dv-right-index { position: absolute; right: -1100px; top: 0; width: 1000px; }
  #dv-right-index .bg { position: absolute; right: 0; top: 20px; width: 100%; }
  #dv-right-index .time { position: absolute; right: 70px; top: 140px; width: 400px; text-align: right; font-size: 18px; font-weight: 600; font-style: normal; }

  #dv-right-index .text{ position: absolute; left: 120px; top: 205px; font-size: 16px; font-weight: bold; width: 810px; }
  #dv-right-index .text span{ color: #00ffff; font-family: 'Work Sans', sans-serif, "microsoft yahei"; }/* 'Teko' */

  #p1 .bg-1{ position: absolute; left: 120px; top: 200px; }
  #p1 .bg-2{ position: absolute; left: 120px; top: 303px; }
  #p1 .bg-3{ position: absolute; left: 516px; top: 200px; }	

  /* 第二栏 */
  #p1 .bg-31{ position: absolute; left: 120px; top: 415px; }
  #p1 .bg-34{ position: absolute; left: 120px; top: 538px; }
  #p1 .bg-32{ position: absolute; left: 251px; top: 415px; }
  #p1 .bg-35{ position: absolute; left: 251px; top: 538px; }
  #p1 .bg-33{ position: absolute; left: 382px; top: 415px; }
  #p1 .bg-36{ position: absolute; left: 382px; top: 538px; }

  #p1 .bg-37{ position: absolute; left: 516px; top: 415px; }

  #p1 .bg-41{ position: absolute; left: 165px; top: 435px; }
  #p1 .bg-42{ position: absolute; left: 296px; top: 435px; }
  #p1 .bg-43{ position: absolute; left: 427px; top: 435px; }
  #p1 .bg-44{ position: absolute; left: 165px; top: 558px; }
  #p1 .bg-45{ position: absolute; left: 296px; top: 558px; }
  #p1 .bg-46{ position: absolute; left: 427px; top: 558px; }

  /* 项目数据列表 */
  #p1 .bg-4{ position: absolute; left: 120px; top: 680px; width: 114px; height: 36px; }
  #p1 .bg-5{ position: absolute; left: 239px; top: 680px; width: 150px; height: 36px; }
  #p1 .bg-6{ position: absolute; left: 120px; top: 728px; width: 806px; height: 36px; }

  #p1 .bg-14{ position: absolute; left: 120px; top: 762px; }
  #p1 .bg-line{ position: absolute; left: -22px; top: 0; }
  #p1 .sign{ position: absolute; left: -22px; top: 0; background: url('/imgs/p101/sign02.png') no-repeat left top; width: 28px; height: 30px; background-size: 28px; cursor: pointer; }
  #p1 .sign.selected{ background-image: url('/imgs/p101/sign01.png') }

  #p1 .bgs .filter{ filter: grayscale(80%) }

  /* 以下是文字 */

  #p1 .text>div { position: absolute; left: 830px; }

  #p1 .text .item-101{ position: absolute; left: 10px; top: 0; font-size: 22px; line-height: 170%; }
  #p1 .text .item-101 span{ font-size: 40px; }
  #p1 .text .item-102{ position: absolute; left: 10px; top: 103px; font-size: 22px; line-height: 170%; }
  #p1 .text .item-102 span{ font-size: 40px; }
  #p1 .text .item-103{ position: absolute; left: 170px; top: 1px; font-size: 22px; }
  #p1 .text .item-104{ position: absolute; left: 285px; top: 1px; font-size: 22px; }
  #p1 .text .item-105{ position: absolute; left: 170px; top: 46px; font-size: 22px; }
  #p1 .text .item-106{ position: absolute; left: 285px; top: 46px; font-size: 22px; }
  #p1 .text .item-107{ position: absolute; left: 170px; top: 104px; font-size: 22px; }
  #p1 .text .item-108{ position: absolute; left: 285px; top: 104px; font-size: 22px; }
  #p1 .text .item-109{ position: absolute; left: 170px; top: 150px; font-size: 22px; }
  #p1 .text .item-110{ position: absolute; left: 285px; top: 150px; font-size: 22px; }

  #p1 .text .item-51{ position: absolute; left: 410px; top: 7px; font-size: 15px; line-height: 170%; }
  #p1 .text .item-52{ position: absolute; left: 610px; top: 30px; font-size: 15px; line-height: 170%; }
  #p1 .text .item-53{ position: absolute; left: 410px; top: 112px; font-size: 15px; line-height: 170%; }
  #p1 .text .item-54{ position: absolute; left: 490px; top: 112px; font-size: 15px; line-height: 170%; }
  #p1 .text .item-55{ position: absolute; left: 620px; top: 112px; font-size: 15px; line-height: 170%; }
  #p1 .text .item-56{ position: absolute; left: 700px; top: 112px; font-size: 15px; line-height: 170%; }

  #p1 .text .item-61{ position: absolute; left: 0; top: 275px; font-size: 15px; width: 125px; text-align: center; }
  #p1 .text .item-71{ position: absolute; left: 0; top: 300px; font-size: 17px; width: 125px; text-align: center; }
  #p1 .text .item-62{ position: absolute; left: 131px; top: 275px; font-size: 15px; width: 125px; text-align: center; }
  #p1 .text .item-72{ position: absolute; left: 131px; top: 300px; font-size: 17px; width: 125px; text-align: center; }
  #p1 .text .item-63{ position: absolute; left: 262px; top: 275px; font-size: 15px; width: 125px; text-align: center; }
  #p1 .text .item-73{ position: absolute; left: 262px; top: 300px; font-size: 17px; width: 125px; text-align: center; }
  #p1 .text .item-64{ position: absolute; left: 0; top: 398px; font-size: 15px; width: 125px; text-align: center; }
  #p1 .text .item-74{ position: absolute; left: 0; top: 423px; font-size: 17px; width: 125px; text-align: center; }
  #p1 .text .item-65{ position: absolute; left: 131px; top: 398px; font-size: 15px; width: 125px; text-align: center; }
  #p1 .text .item-75{ position: absolute; left: 131px; top: 423px; font-size: 17px; width: 125px; text-align: center; }
  #p1 .text .item-66{ position: absolute; left: 262px; top: 398px; font-size: 15px; width: 125px; text-align: center; }
  #p1 .text .item-76{ position: absolute; left: 262px; top: 423px; font-size: 17px; width: 125px; text-align: center; }

  #p1 .text .item-81{ position: absolute; left: 430px; top: 230px; font-size: 12px; width: 125px; font-style: normal; text-align: center; }
  #p1 .text .item-82{ position: absolute; left: 404px; top: 307px; font-size: 12px; width: 30px; font-style: normal; text-align: center; }
  #p1 .text .item-83{ position: absolute; left: 552px; top: 307px; font-size: 12px; width: 30px; font-style: normal; text-align: center; }
  #p1 .text .item-84{ position: absolute; left: 430px; top: 400px; font-size: 12px; width: 125px; font-style: normal; text-align: center; }
  #p1 .text .item-85{ position: absolute; left: 600px; top: 225px; font-size: 12px; width: 125px; font-style: normal; }
  #p1 .text .item-86{ position: absolute; left: 600px; top: 255px; font-size: 14px; width: 125px; font-style: normal; }
  #p1 .text .item-87{ position: absolute; left: 600px; top: 301px; font-size: 14px; width: 125px; font-style: normal; }
  #p1 .text .item-89{ position: absolute; left: 600px; top: 347px; font-size: 14px; width: 125px; font-style: normal; }
  #p1 .text .item-88{ position: absolute; left: 600px; top: 393px; font-size: 14px; width: 125px; font-style: normal; }

  #p1 .text .item-91{ position: absolute; left: 752px; top: 272px; font-size: 16px; width: 30px; text-align: right; font-style: normal; }
  #p1 .text .item-92{ position: absolute; left: 752px; top: 318px; font-size: 16px; width: 30px; text-align: right; font-style: normal; }
  #p1 .text .item-94{ position: absolute; left: 752px; top: 364px; font-size: 16px; width: 30px; text-align: right; font-style: normal; }
  #p1 .text .item-93{ position: absolute; left: 752px; top: 410px; font-size: 16px; width: 30px; text-align: right; font-style: normal; }

  #p1 .text .bar2d_01{ position: absolute; left: 600px; top: 278px; width: 150px; }
  #p1 .text .bar2d_02{ position: absolute; left: 600px; top: 324px; width: 150px; }
  #p1 .text .bar2d_04{ position: absolute; left: 600px; top: 370px; width: 150px; }
  #p1 .text .bar2d_03{ position: absolute; left: 600px; top: 416px; width: 150px; }

  /* 项目数据列表 */
  #p1 .text .item-3{ position: absolute; left: 15px; top: 479px; font-size: 17px; line-height: 170%; cursor: pointer; }
  #p1 .text .item-4{ position: absolute; left: 134px; top: 479px; font-size: 17px; line-height: 170%; cursor: pointer; }

  #p1 .text .pageBtns{ position: absolute; left: 715px; top: 479px; width: 80px; text-align: right; }
  #p1 .text .pageBtns .pagebtn{ margin: 0 3px }

  #p1 .text .item-5{ position: absolute; left: 20px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p1 .text .item-6{ position: absolute; left: 173px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p1 .text .item-7{ position: absolute; left: 258px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p1 .text .item-8{ position: absolute; left: 328px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p1 .text .item-9{ position: absolute; left: 415px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p1 .text .item-10{ position: absolute; left: 515px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p1 .text .item-11{ position: absolute; left: 615px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }
  #p1 .text .item-12{ position: absolute; left: 715px; top: 525px; font-size: 14px; font-weight: normal; line-height: 250%; }

  #p1 .text .item-21{ position: absolute; left: 410px; top: 22px; font-size: 48px; }
  #p1 .text .item-22{ position: absolute; left: 610px; top: 50px; font-size: 22px; }
  #p1 .text .item-23{ position: absolute; left: 410px; top: 139px; font-size: 28px; }
  #p1 .text .item-24{ position: absolute; left: 490px; top: 139px; font-size: 28px; }
  #p1 .text .item-25{ position: absolute; left: 620px; top: 139px; font-size: 28px; }
  #p1 .text .item-26{ position: absolute; left: 700px; top: 139px; font-size: 28px; }

  #p1 .text .ProgramList { position: absolute; left: -10px; top:565px; width: 820px; font-size: 15px; height: 150px; overflow: hidden; }
  #p1 .text .ProgramList .program { position: absolute; left: 30px; width: 800px; }
  #p1 .text .ProgramList .program span:nth-of-type(1){ position: absolute; left: 18px; top: 3px; color: #ffffff; cursor: pointer; }
  #p1 .text .ProgramList .program span:nth-of-type(2){ position: absolute; left: 150px; top: 3px; width: 65px; text-align: right; }
  #p1 .text .ProgramList .program span:nth-of-type(3){ position: absolute; left: 150px; top: 3px; width: 145px; text-align: right; }
  #p1 .text .ProgramList .program span:nth-of-type(4){ position: absolute; left: 310px; top: 3px; width: 60px; text-align: right; }
  #p1 .text .ProgramList .program .bar3d1{ position: absolute; left: 395px; top: 5px; width: 80px; }
  #p1 .text .ProgramList .program .bar3d2{ position: absolute; left: 495px; top: 5px; width: 80px; }
  #p1 .text .ProgramList .program .bar3d3{ position: absolute; left: 595px; top: 5px; width: 80px; }
  #p1 .text .ProgramList .program .bar3d4{ position: absolute; left: 695px; top: 5px; width: 80px; }
  #p1 .text .ProgramList .program .text span{ font-weight: normal; }

	#p1 .echarts #chart04{ position: absolute; left: 538px; top: 451px; width: 145px; height: 145px; }

</style>

<template>
  <div id="dv-right-index">
    <dv-img class="bg" src="../imgs/img/right/right.json" type="svg" loop="true"></dv-img>
    <i class="time" key="trans-02" v-text="currentTime"></i>

    <div class="page" id="p1">
      <div class="bgs">
        <dv-img class="bg-1" src="imgs/p1/已监控项目-bg.png"></dv-img>
        <dv-img class="bg-2" src="imgs/p1/已监控项目-bg.png"></dv-img>
        
        <dv-img class="bg-3" src="imgs/p2/bae6b497-6209-4227-8443-7b63a1629994.png"></dv-img>
        
        <!-- 第二栏 -->
        <dv-img class="bg-31" src="imgs/p2/1d764daf-c95b-4201-aa0e-a4f3f042d556.png"></dv-img>
        <dv-img class="bg-32" src="imgs/p2/1d764daf-c95b-4201-aa0e-a4f3f042d556.png"></dv-img>
        <dv-img class="bg-33" src="imgs/p2/1d764daf-c95b-4201-aa0e-a4f3f042d556.png"></dv-img>
        <dv-img class="bg-34" src="imgs/p2/1d764daf-c95b-4201-aa0e-a4f3f042d556.png"></dv-img>
        <dv-img class="bg-35" src="imgs/p2/1d764daf-c95b-4201-aa0e-a4f3f042d556.png"></dv-img>
        <dv-img class="bg-36" src="imgs/p2/1d764daf-c95b-4201-aa0e-a4f3f042d556.png"></dv-img>
        
        <dv-img class="bg-37" src="imgs/p2/9c5b65e2-e170-4cfc-ae35-3f9665a7ad48.png"></dv-img>
        
        <dv-img class="bg-41" src="imgs/p2/34c3d01e-9939-4ccd-9815-c665b0c2b277.png"></dv-img>
        <dv-img class="bg-42" src="imgs/p2/7662f8c6-3814-4f39-bf80-59460734fd57.png"></dv-img>
        <dv-img class="bg-43" src="imgs/p2/41ba0cbe-5050-445d-b83b-0e16ee05cdf6.png"></dv-img>
        <dv-img class="bg-44" src="imgs/p2/b69c8cc1-ec6e-4825-8ade-4db7be554ac5.png"></dv-img>
        <dv-img class="bg-45" src="imgs/p2/5bbae94f-c50a-4a37-a805-306f0ad0cada.png"></dv-img>
        <dv-img class="bg-46" src="imgs/p2/54f99eb4-03c2-4eb9-ac62-8c4324254cfe.png"></dv-img>
        
        <!-- 项目数据列表 -->
        <dv-img :class="'bg-4' + (contrastIndex == 1 ? '' : ' filter')" @click="toggleContrastBtn" data-index="1" src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"></dv-img>
        <dv-img :class="'bg-5' + (contrastIndex == 1 ? ' filter' : '')" @click="toggleContrastBtn" data-index="2" src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"></dv-img>
        <dv-img class="bg-6" src="imgs/p2/06a794e5-ca98-4ef4-92e5-94c66d96e2d0.png"></dv-img>
        <dv-img class="bg-14" src="imgs/p2/line.png"></dv-img>
      </div>
      <div class="text">
        <div class="item-101">已监控项目<br><span v-text="MonitorProjectCount">0</span></div>
        <div class="item-102">已监控隧道<br><span v-text="MonitorTunnelCount">0</span></div>
        <div class="item-103">铁路 <span v-text="MonitorProjectCount1">0</span></div>
        <div class="item-104">市政 <span v-text="MonitorProjectCount2">0</span></div>
        <div class="item-105">公路 <span v-text="MonitorProjectCount3">0</span></div>
        <div class="item-106">水利 <span v-text="MonitorProjectCount4">0</span></div>
        <div class="item-107">铁路 <span v-text="MonitorTunnelCount1">0</span></div>
        <div class="item-108">市政 <span v-text="MonitorTunnelCount2">0</span></div>
        <div class="item-109">公路 <span v-text="MonitorTunnelCount3">0</span></div>
        <div class="item-110">水利 <span v-text="MonitorTunnelCount4">0</span></div>
        
        <div class="item-51">整改合格率</div>
        <div class="item-52">重大缺陷整改合格率</div>
        <div class="item-53">缺陷总数</div>
        <div class="item-54">重大缺陷总数</div>
        <div class="item-55">缺陷整改</div>
        <div class="item-56">重大缺陷整改</div>
        
        <div class="item-21"><span v-text="TotalFixRate + '%'"></span></div>
        <div class="item-22"><span v-text="TotalFixBadRate + '%'"></span></div>
        <div class="item-23"><span v-text="TotalFaultCount"></span></div>
        <div class="item-24"><span v-text="TotalFaultBadCount"></span></div>
        <div class="item-25"><span v-text="TotalFixCount"></span></div>
        <div class="item-26"><span v-text="TotalFixBadCount"></span></div>
        
        <!-- 第二栏 -->
        <div class="item-61">规划总长</div>
        <div class="item-62">衬砌总长</div>
        <div class="item-63">已检总长</div>
        <div class="item-64">已检覆盖率</div>
        <div class="item-65">缺陷率</div>
        <div class="item-66">重大缺陷率</div>
        <div class="item-71"><span v-text="DesignLength + 'KM'"></span></div>
        <div class="item-72"><span v-text="CrackLength + 'KM'"></span></div>
        <div class="item-73"><span v-text="TestLength + 'KM'"></span></div>
        <div class="item-74"><span v-text="TestRate + '%'"></span></div>
        <div class="item-75"><span v-text="TotalFaultRate + '%'"></span></div>
        <div class="item-76"><span v-text="TotalFaultBadRate + '%'"></span></div>
        
        <div class="item-81">厚度不足</div>
        <div class="item-82">钢筋缺失</div>
        <div class="item-83">空洞不密实</div>
        <div class="item-84">防裂钢筋网</div>
        <div class="item-85">缺陷数量</div>
        <div class="item-86">厚度不足</div>
        <div class="item-87">钢筋缺失</div>
        <div class="item-88">空洞、不密实</div>
        <div class="item-89">防裂钢筋网</div>
        
        <div class="item-91"><span v-text="FaultCount1"></span></div>
        <div class="item-92"><span v-text="FaultCount2"></span></div>
        <div class="item-93"><span v-text="FaultCount4"></span></div>
        <div class="item-94"><span v-text="FaultCount3"></span></div>
        
        <dv-bar-2d class="bar2d_01" height="12px" barcolorh="0" during="3"   :value="FaultCount1 / (FaultCount1 + FaultCount2 + FaultCount3 + FaultCount4) * 100"></dv-bar-2d>
        <dv-bar-2d class="bar2d_02" height="12px" barcolorh="30" during="3"  :value="FaultCount2 / (FaultCount1 + FaultCount2 + FaultCount3 + FaultCount4) * 100"></dv-bar-2d>
        <dv-bar-2d class="bar2d_03" height="12px" barcolorh="190" during="3" :value="FaultCount4 / (FaultCount1 + FaultCount2 + FaultCount3 + FaultCount4) * 100"></dv-bar-2d>
        <dv-bar-2d class="bar2d_04" height="12px" barcolors="5%" during="3"  :value="FaultCount3 / (FaultCount1 + FaultCount2 + FaultCount3 + FaultCount4) * 100"></dv-bar-2d>
        
        <!-- 项目数据列表 -->
        <div class="item-3" @click="toggleContrastBtn" data-index="1">缺陷率对比</div>
        <div class="item-4" @click="toggleContrastBtn" data-index="2">整改合格率对比</div>
        <div class="item-5">项目名称</div>
        <div class="item-6">检测覆盖率</div>
        <div class="item-7" v-text="contrastIndex == 1 ? '总缺陷率' : '已整改率'"></div>
        <div class="item-8" v-text="contrastIndex == 1 ? '7天验证超时' : '7天验证超时'"></div>
        <div class="item-9">厚度不足</div>
        <div class="item-10">钢筋缺失</div>
        <div class="item-11">防裂钢筋网</div>
        <div class="item-12">空洞、不密实</div>
        
        <div class="pageBtns">
          <dv-img class="pagebtn" v-for="pageIndex in (Math.floor(getContrast.length / 5) + (getContrast.length % 5 > 0 ? 1 : 0))" :key="'page_' + pageIndex" :title="pageIndex" @mouseover="selectPage" :data-index="pageIndex" :src="'imgs/p2/' + (currentPage == pageIndex ? '06f15087-c767-45be-977e-2e00800cfac6' : '2dc0cea5-876d-4e53-9c26-10debca2d21b') + '.png'"></dv-img>
        </div>

        <div style="position: absolute; left: 290px; top: 474px; line-height: 36px; padding: 0 20px; border: 1px solid #2CF7FE; background-color: #083866; color: #2CF7FE; cursor: pointer;" @click="ComparePrograms">数据对比</div>
              
        <div class="ProgramList" :style="'height: ' + pagesize * 30 + 'px'" @mouseover="pageMouseOver" @mouseleave="pageMouseLeave">
          <div class="program" v-for="(item, index) in getContrast" :key="index" :style="'top: ' + (0 + 30 * (index)) + 'px'">
            <dv-img class="bg-line" src="imgs/p2/line-bg.png"></dv-img>
            <div :class="'sign' + (needCompareIds.indexOf(item.Id) > -1 ? ' selected' : '')" @click="toggleSelect(item.Id)"></div>
            <span v-text="item.Name" @click="selectProgram(2, item.Id)"></span>
            <span v-text="item.TestRate + '%'"></span>
            <span v-text="item.DataRate + '%'"></span>
            <span v-text="item.OverTime7Count + '处'"></span>
            <dv-bar-3d class="bar3d1" :value="item.DataRate1" height="16px" :barcolorh="contrastIndex == 1 ? '0' : '120'" during="3" showtext="true"></dv-bar-3d>
            <dv-bar-3d class="bar3d2" :value="item.DataRate2" height="16px" :barcolorh="contrastIndex == 1 ? '30' : '120'" during="3" showtext="true"></dv-bar-3d>
            <dv-bar-3d class="bar3d3" :value="item.DataRate3" height="16px" :barcolorh="contrastIndex == 1 ? '0' : '120'" :barcolors="contrastIndex == 1 ? '5%' : '100%'" during="3" showtext="true"></dv-bar-3d>
            <dv-bar-3d class="bar3d4" :value="item.DataRate4" height="16px" :barcolorh="contrastIndex == 1 ? '190' : '120'" during="3" showtext="true"></dv-bar-3d>
          </div>
          <div class="program" v-for="index in (getContrast.length < 5 ? 5 - getContrast.length : 0)" :style="'top: ' + (0 + 30 * (index + getContrast.length - 1)) + 'px'" :key="index">
            <dv-img class="bg-line" src="imgs/p2/line-bg.png"></dv-img>
          </div>
        </div>
      </div>
      
      <div class="echarts">
        <div id="chart04"></div>
      </div>
    </div>

  </div>
</template>

<script>
import * as $ from 'jquery'
import * as echarts from 'echarts'
import dvImg from "./dv-img.vue"
import dataV from '../../bin/dataV'
import dvBar3d from "./dv-bar-3d.vue"
import dvBar2d from "./dv-bar-2d.vue"

export default {
  data() {
    return {
      Id: 'RightIndexComponent',

      CrackLength: window.ApiData.index.CrackLength,
      DesignLength: window.ApiData.index.DesignLength,
      FaultCount1: window.ApiData.index.FaultCount1,
      FaultCount2: window.ApiData.index.FaultCount2,
      FaultCount3: window.ApiData.index.FaultCount3,
      FaultCount4: window.ApiData.index.FaultCount4,
      MonitorProjectCount: window.ApiData.index.MonitorProjectCount,
      MonitorProjectCount1: window.ApiData.index.MonitorProjectCount1,
      MonitorProjectCount2: window.ApiData.index.MonitorProjectCount2,
      MonitorProjectCount3: window.ApiData.index.MonitorProjectCount3,
      MonitorProjectCount4: window.ApiData.index.MonitorProjectCount4,
      MonitorTunnelCount: window.ApiData.index.MonitorTunnelCount,
      MonitorTunnelCount1: window.ApiData.index.MonitorTunnelCount1,
      MonitorTunnelCount2: window.ApiData.index.MonitorTunnelCount2,
      MonitorTunnelCount3: window.ApiData.index.MonitorTunnelCount3,
      MonitorTunnelCount4: window.ApiData.index.MonitorTunnelCount4,
      ProjectCompareFault: window.ApiData.index.ProjectCompareFault,
      ProjectCompareFix: window.ApiData.index.ProjectCompareFix,
      ProjectList: window.ApiData.index.ProjectList,
      SignProjectList: window.ApiData.index.SignProjectList,
      TestLength: window.ApiData.index.TestLength,
      TestRate: window.ApiData.index.TestRate,
      TotalFaultBadCount: window.ApiData.index.TotalFaultBadCount,
      TotalFaultBadRate: window.ApiData.index.TotalFaultBadRate,
      TotalFaultCount: window.ApiData.index.TotalFaultCount,
      TotalFaultRate: window.ApiData.index.TotalFaultRate,
      TotalFixBadCount: window.ApiData.index.TotalFixBadCount,
      TotalFixBadRate: window.ApiData.index.TotalFixBadRate,
      TotalFixCount: window.ApiData.index.TotalFixCount,
      TotalFixRate: window.ApiData.index.TotalFixRate,

      currentTime: dataV.DateTime.format('YYYY-mm-dd HH:MM:SS') + '　' + dataV.DateTime.getWeekCH(),

      currentPageLevel: dataV.Config.initPage.level,		// 大屏当前页面层级，1-全国、2-项目部
      currentArea: dataV.Config.initPage.area,
      currentProgram: dataV.Config.initPage.program,
      currentTunnel: dataV.Config.initPage.tunnel,
      currentTunnelID: dataV.Config.initPage.tunnelId,
      currentDefect: dataV.Config.initPage.defect,

      chart03index: 1,
      pagesize: 5,				// 项目、隧道、缺陷列表显示记录数
      currentPage: 1,				// 项目、隧道、缺陷列表当前页码
      currentPageTimer: null,
      contrastIndex: 1, 			// 项目列表 或 隧道列表，对比维度：1-缺陷率对比，2-整改合格率对比
      pageToggleKey: true,		// 是否允许列表页自动翻页
      maxPage: dataV.Config.initPage.maxPage,

      needCompareIds: []

    }
  },
  props: [],
  methods: {

    toggleSelect(Id){
      const index = this.needCompareIds.indexOf(Id)
      if(index > -1) this.needCompareIds = this.needCompareIds.slice(0, index).concat(this.needCompareIds.slice(index + 1))
      else this.needCompareIds.push(Id)
    },

    ComparePrograms(){
      const Ids = this.needCompareIds.filter(item => item).join(',')
      if(Ids) window.RightListComponent.ComparePrograms(Ids)
      else dataV.ModelWindows.close()
    },

    selectProgram(level, id){
			dataV.Page.Toggle(this, level, id)
      // 跟随按钮，切换所有板块的标识
      // var dvRightMenu = this.$parent.$children.filter((item) => { return item.$el.id == 'dv-right-menu' })[0];
      // if(name && typeof name == 'string'){
      //   dvRightMenu.animate(2, null, name);
      // }else{
      //   dvRightMenu.animate(2, null, $(event.target).text());
      // }
    },

    
    selectPage(){		// 数据列表，手动换页
      this.currentPage = $(event.target).data('index');
      this.changePage(this.getContrast.length);
    },

    changePage(dataLength){
      var that = this;
      //
      if(this.currentPageTimer) clearInterval(this.currentPageTimer);
      var defectPageCount = Math.floor(dataLength / this.pagesize) + (dataLength % this.pagesize > 0 ? 1 : 0);
      if(defectPageCount > 1) {
        if(this.currentPageTimer) {
          clearInterval(this.currentPageTimer)
          this.currentPageTimer = null
        }
        this.currentPageTimer = setInterval(function(){
          if(that.pageToggleKey){
            var newpage = that.currentPage + 1;
            if(newpage > defectPageCount) newpage = 1;
            that.currentPage = newpage;
          }
        }, 5000);
      }
    },

    toggleContrastBtn(){
      this.contrastIndex = $(event.target).data('index');
    },
    pageMouseOver(){
      this.pageToggleKey = false;
    },
    pageMouseLeave(){
      this.pageToggleKey = true;
    },

    ShowClock(){
      var that = this;
      setInterval(function(){
        const CurrentDateTime = dataV.DateTime.format('YYYY-mm-dd HH:MM:SS') + '　' + dataV.DateTime.getWeekCH();
        that.currentTime = CurrentDateTime
        if(window.RightProgramComponent) window.RightProgramComponent.currentTime = CurrentDateTime
        if(window.RightTunnelComponent) window.RightTunnelComponent.currentTime = CurrentDateTime
      }, 500)
    },

    p2Radar(){

      var counts = [
        window.ApiData.index.FaultCount1, 
        window.ApiData.index.FaultCount2, 
        window.ApiData.index.FaultCount3, 
        window.ApiData.index.FaultCount4, 
      ];
      var max = counts.slice().sort((a,b)=>{ return b-a })[0];
      
      var chart04 = echarts.init(document.getElementById('chart04'));
      var option = {
        tooltip: {},
        radar: {
          shape: 'circle',
          axisName: {
            textStyle: {
              padding: [50]	// 设置一个较大值，将各项的名称隐藏在图表之外
            }
          },
          splitArea: {
            areaStyle: {
              color: ['rgba(0,51,89, 0.8)', 'rgba(1,71,105, 0.8)', 'rgba(0,51,89, 0.8)', 'rgba(1,71,105, 0.8)', 'rgba(0,51,89, 0.8)', 'rgba(1,71,105, 0.8)', 'rgba(0,51,89, 0.8)'],
            },
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.5)'
            }
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.5)'
            }
          },
          indicator: [
            { name: '厚度不足', max: max},
            { name: '钢筋缺失', max: max},
            { name: '防裂钢筋网', max: max},
            { name: '空洞、不密实', max: max}
          ]
        },
        series: [{
          type: 'radar',
          areaStyle: {
            opacity: 0.5,
            color: new echarts.graphic.RadialGradient(0.5, 0.5, 1, [
              {
                color: '#aaffff',
                offset: 0
              },
              {
                color: '#0085b9',
                offset: 0.5
              }
            ])
          },
          symbol: 'none',
          lineStyle: {
            color: '#0085b9',
          },
          data: [
            {
              value: counts,
              name: '缺陷数量'
            }
          ]
        }]
      };
      chart04.setOption(option);
    },

  },
  computed: {
    defects(){
      return this.tunnelInfoPatch.defects.filter((item)=>{ return item.type == this.currentDefect })
    },

    getProgs(){		// 返回p2的监控项目和隧道数量
      var returnValue = {};
      if (this.currentArea == '全国区域') {	
        returnValue = this.prog;
      } else if (this.currentArea == '西北区域' || this.currentArea == '西南区域' || this.currentArea == '东北区域' || this.currentArea == '检测基地') {
        var railways_progra_count  = this.prog.railways.areas [this.currentArea]  ? Object.keys(this.prog.railways.areas [this.currentArea].programs).length : 0;
        var highways_progra_count  = this.prog.highways.areas [this.currentArea]  ? Object.keys(this.prog.highways.areas [this.currentArea].programs).length : 0;
        var subways_progra_count   = this.prog.subways.areas [this.currentArea]   ? Object.keys(this.prog.subways.areas  [this.currentArea].programs).length : 0;
        var waterways_progra_count = this.prog.waterways.areas [this.currentArea] ? Object.keys(this.prog.waterways.areas[this.currentArea].programs).length : 0;
        
        var railways_tunnel_count  = this.prog.railways.areas [this.currentArea] ? this.prog.railways.areas [this.currentArea].tunnelCount : 0;
        var highways_tunnel_count  = this.prog.highways.areas [this.currentArea] ? this.prog.highways.areas [this.currentArea].tunnelCount : 0;
        var subways_tunnel_count   = this.prog.subways.areas  [this.currentArea] ? this.prog.subways.areas  [this.currentArea].tunnelCount : 0;
        var waterways_tunnel_count = this.prog.waterways.areas[this.currentArea] ? this.prog.waterways.areas[this.currentArea].tunnelCount : 0;
        
        returnValue = {
          programCount: railways_progra_count + highways_progra_count + subways_progra_count + waterways_progra_count,
          tunnelCount: railways_tunnel_count + highways_tunnel_count + subways_tunnel_count + waterways_tunnel_count,
          railways: {
            programCount: railways_progra_count,
            tunnelCount: railways_tunnel_count,
          },
          highways: {
            programCount: highways_progra_count,
            tunnelCount: highways_tunnel_count,
          },
          subways: {
            programCount: subways_progra_count,
            tunnelCount: subways_tunnel_count,
          },
          waterways: {
            programCount: waterways_progra_count,
            tunnelCount: waterways_tunnel_count,
          },
        }
      }

      return returnValue
    },

    getContrast () {  // 项目的对比列表
      if(this.contrastIndex == 1){  // 返回 缺陷率对比列表
        return this.ProjectCompareFault
      }else{        // 返回 整改合格率对比列表
        return this.ProjectCompareFix
      }
    }

  },
  watch: {
    
    currentPage(){
      var targetTop = (this.currentPage - 1) * 5 * 30;
      if(dataV.Page.CurrentLevel === 1){
        $('#p2 .text .ProgramList').animate({
          scrollTop: targetTop + 'px'
        }, 'slow');
      }else if(dataV.Page.CurrentLevel === 2){
        $('#p3 .tunnelList .ProgramList').animate({
          scrollTop: targetTop + 'px'
        }, 'slow');
      }else if(dataV.Page.CurrentLevel === 3){
        targetTop = (this.currentPage - 1) * this.pagesize * 30;
        $('#p4 .defectList .pageList').animate({
          scrollTop: targetTop + 'px'
        }, 'slow');
      }
    },

    TunnelCompareFault(){
      this.changePage(this.getContrast.length);
    }

  },
  created() {
			window[this.Id] =  this;
  },
  mounted() {
    this.ShowClock()
    this.p2Radar()
  },
  components: {
    "dv-img": dvImg,
    'dv-bar-3d': dvBar3d,
    'dv-bar-2d': dvBar2d,
  },
};
</script>
