<style>
#dv-header {
  position: absolute;
  left: 0;
  top: 0px;
  text-align: center;
}
#dv-header .bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 3840px;
  height: 109px;
}
</style>

<template>
  <div id="dv-header">
    <dv-img class="bg" src="./imgs/head.png"></dv-img>
  </div>
</template>

<script>
import dvImg from "./dv-img.vue";

export default {
  props: ["title"], 
  methods: {},
  components: {
    'dv-img': dvImg,
  },
};
</script>
