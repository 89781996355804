<style>
  #dv-left-program { position: absolute; left: -1100px; top: 0; width: 1000px; }
  #dv-left-program .bg { position: absolute; left: 0; top: 20px; width: 100%; }

  #program_caption { position: absolute; left: 75px; top: 130px; font-size: 27px; font-weight: 550; }

  #p_Left_2 { position: absolute; left: 0; top: 0; }
  #p_Left_2 .text{ font-family: 'Work Sans', sans-serif, "microsoft yahei"; font-size: 15px; }/* 'Teko' */
  #p_Left_2 .text span{ color: #00ffff; }/* 'Teko' */
  #p_Left_2 .bg01{ position: absolute; left: 90px; top: 194px; }
  #p_Left_2 .videos{ position: absolute; left: 108px; top: 180px; width: 280px; }
  #p_Left_2 .videos .videoBox{ position: absolute; left: 9px; top: 40px; width: 280px; height: 158px; border: 1px solid #0055AA; text-align: center; line-height: 176px; color: #0055AA; }
  #p_Left_2 .videos .video-0-bg{ position: absolute; left: 156px; top: 88px; width: 0; height: 0; }
  #p_Left_2 .videos .video-0{ position: absolute; left: 0; top: 0; width: 280px; height: 158px; }
  #p_Left_2 .videos .smallpic{ position: absolute; top: 205px; width: 90px; height: 50px; }
  #p_Left_2 .videos .video-1{ left: 9px; }
  #p_Left_2 .videos .video-2{ left: 105px; }
  #p_Left_2 .videos .video-3{ left: 201px; }
  
  #p_Left_2 .address{ position: absolute; left: 440px; top: 230px; width: 430px; }
  #p_Left_2 .department{ position: absolute; left: 440px; top: 255px; width: 430px; padding-bottom: 15px; border-bottom: 1px solid #000088; }
  #p_Left_2 .explains{ position: absolute; left: 440px; top: 305px; width: 430px; line-height: 170%; height: 120px; overflow: hidden; }
  #p_Left_2 .bg02{ position: absolute; left: 90px; top: 475px; }
  #p_Left_2 .lable_tnlTotalLength{ position: absolute; left: 108px; top: 495px; width: 200px; font-weight: bold; }
  #p_Left_2 .lable_tnlBetonLength{ position: absolute; left: 310px; top: 495px; width: 200px; font-weight: bold; }
  #p_Left_2 .lable_tnlCheckLength{ position: absolute; left: 512px; top: 495px; width: 200px; font-weight: bold; }
  #p_Left_2 .lable_tnlCheckRate  { position: absolute; left: 714px; top: 495px; width: 200px; font-weight: bold; }
  #p_Left_2 .value_tnlTotalLength{ position: absolute; left: 108px; top: 510px; width: 200px; font-size: 42px; }
  #p_Left_2 .value_tnlBetonLength{ position: absolute; left: 310px; top: 510px; width: 200px; font-size: 42px; }
  #p_Left_2 .value_tnlCheckLength{ position: absolute; left: 512px; top: 510px; width: 200px; font-size: 42px; }
  #p_Left_2 .value_tnlCheckRate  { position: absolute; left: 714px; top: 510px; width: 200px; font-size: 42px; }
  #p_Left_2 .bg03{ position: absolute; left: 88px; top: 595px; }
  #p_Left_2 .bg05{ position: absolute; left: 120px; top: 750px; }
  #p_Left_2 .bg05_bg{ position: absolute; left: 105px; top: 730px; }
  #p_Left_2 .lable_defectRepairRate { position: absolute; left: 112px; top: 605px; width: 120px; font-weight: bold; }
  #p_Left_2 .lable_keyDefectRepairRate { position: absolute; left: 112px; top: 735px; width: 200px; font-weight: bold; }
  #p_Left_2 .value_defectRepairRate { position: absolute; left: 112px; top: 640px; width: 200px; font-size: 50px; font-weight: bold; }
  #p_Left_2 .value_keyDefectRepairRate { position: absolute; left: 112px; top: 755px; width: 120px; font-size: 34px; font-weight: bold; }
  #p_Left_2 .bg04-1{ position: absolute; left: 295px; top: 595px; }
  #p_Left_2 .bg04-2{ position: absolute; left: 498px; top: 595px; }
  #p_Left_2 .bg04-3{ position: absolute; left: 701px; top: 595px; }
  #p_Left_2 .bg04-4{ position: absolute; left: 295px; top: 714px; }
  #p_Left_2 .bg04-5{ position: absolute; left: 498px; top: 714px; cursor: pointer; }
  #p_Left_2 .bg04-6{ position: absolute; left: 701px; top: 714px; cursor: pointer; }
  #p_Left_2 .lable_thickCount { position: absolute; left: 305px; top: 598px; width: 190px; font-weight: bold; }
  #p_Left_2 .lable_otherCount { position: absolute; left: 508px; top: 598px; width: 190px; font-weight: bold; }
  #p_Left_2 .lable_rebarCount { position: absolute; left: 711px; top: 598px; width: 190px; font-weight: bold; }
  #p_Left_2 .lable_betonCount { position: absolute; left: 305px; top: 717px; width: 190px; font-weight: bold; }
  #p_Left_2 .lable_betonCount2 { position: absolute; left: 508px; top: 717px; width: 190px; font-weight: bold; pointer-events: none; }
  #p_Left_2 .lable_betonCount3 { position: absolute; left: 711px; top: 717px; width: 190px; font-weight: bold; pointer-events: none; }
  #p_Left_2 .value_thickCount { position: absolute; left: 305px; top: 645px; width: 190px; font-size: 42px; font-weight: bold; }
  #p_Left_2 .value_otherCount { position: absolute; left: 508px; top: 645px; width: 190px; font-size: 42px; font-weight: bold; }
  #p_Left_2 .value_rebarCount { position: absolute; left: 711px; top: 645px; width: 190px; font-size: 42px; font-weight: bold; }
  #p_Left_2 .value_betonCount { position: absolute; left: 305px; top: 764px; width: 190px; font-size: 42px; font-weight: bold; }
  #p_Left_2 .value_betonCount2 { position: absolute; left: 508px; top: 764px; width: 190px; font-size: 42px; font-weight: bold; pointer-events: none; }
  #p_Left_2 .value_betonCount3 { position: absolute; left: 711px; top: 764px; width: 190px; font-size: 42px; font-weight: bold; pointer-events: none; }
  #p_Left_2 .bg05-1{ position: absolute; left: 414px; top: 630px; width: 72px; }
  #p_Left_2 .bg05-2{ position: absolute; left: 617px; top: 630px; width: 72px; }
  #p_Left_2 .bg05-3{ position: absolute; left: 820px; top: 630px; width: 72px; }
  #p_Left_2 .bg05-4{ position: absolute; left: 414px; top: 749px; width: 72px; }
  #p_Left_2 .bg05-5{ position: absolute; left: 617px; top: 749px; width: 72px; pointer-events: none; }
  #p_Left_2 .bg05-6{ position: absolute; left: 820px; top: 749px; width: 72px; pointer-events: none; }
  #p_Left_2 .rate_thickCount { position: absolute; left: 388px; top: 652px; width: 124px; font-size: 22px; text-align: center; font-weight: bold; }
  #p_Left_2 .rate_otherCount { position: absolute; left: 591px; top: 652px; width: 124px; font-size: 22px; text-align: center; font-weight: bold; }
  #p_Left_2 .rate_rebarCount { position: absolute; left: 794px; top: 652px; width: 124px; font-size: 22px; text-align: center; font-weight: bold; }
  #p_Left_2 .rate_betonCount { position: absolute; left: 388px; top: 771px; width: 124px; font-size: 22px; text-align: center; font-weight: bold; }
  #p_Left_2 .rate_betonCount2 { position: absolute; left: 591px; top: 771px; width: 124px; font-size: 22px; text-align: center; font-weight: bold; pointer-events: none; }
  #p_Left_2 .rate_betonCount3 { position: absolute; left: 794px; top: 771px; width: 124px; font-size: 22px; text-align: center; font-weight: bold; pointer-events: none; }
  #p_Left_2 .rate_thickCount span { color: #FE0001; }
  #p_Left_2 .rate_otherCount span { color: #0055FE; }
  #p_Left_2 .rate_rebarCount span { color: #FFA518; }
  #p_Left_2 .rate_betonCount span { color: #9094A0; }
  #p_Left_2 .rate_betonCount3 span { color: #8428A1; }
  #p_Left_2 .bg06{ position: absolute; left: 90px; top: 835px; width: 268px; }
  #p_Left_2 .bg07{ position: absolute; left: 375px; top: 835px; width: 253px; }
  #p_Left_2 .bg08{ position: absolute; left: 644px; top: 835px; width: 253px; }
  #p_Left_2 .lable_defectInfo { position: absolute; left: 108px; top: 840px; width: 200px; font-weight: bold; }
  #p_Left_2 .lable_keyDefectInfo { position: absolute; left: 393px; top: 840px; width: 200px; font-weight: bold; }
  #p_Left_2 .lable_tunnelInfo { position: absolute; left: 662px; top: 840px; width: 200px; font-weight: bold; }
  #p_Left_2 .value_defectInfo    { position: absolute; left: 108px; top: 880px; width: 230px; font-weight: bold; }
  #p_Left_2 .value_keyDefectInfo { position: absolute; left: 393px; top: 880px; width: 215px; font-weight: bold; }
  #p_Left_2 .value_tunnelInfo    { position: absolute; left: 662px; top: 880px; width: 215px; font-weight: bold; }
  #p_Left_2 .value_defectInfo    span { font-size: 36px; }
  #p_Left_2 .value_keyDefectInfo span { font-size: 36px; }
  #p_Left_2 .value_tunnelInfo    span { font-size: 36px; }
  #p_Left_2 .value_defectInfo    div:nth-child(1),
  #p_Left_2 .value_tunnelInfo    div:nth-child(1),
  #p_Left_2 .value_keyDefectInfo div:nth-child(1) { position: absolute; left: 0; top: 0; width: 120px; font-weight: bold; text-align: left; }
  #p_Left_2 .value_defectInfo    i { position: absolute; left: 105px; top: 0; font-size: 36px; font-style: normal; color: #5d91a7; }
  #p_Left_2 .value_tunnelInfo    i,
  #p_Left_2 .value_keyDefectInfo i { position: absolute; left: 95px; top: 0; font-size: 36px; font-style: normal; color: #5d91a7; }
  #p_Left_2 .value_defectInfo    div:nth-child(3),
  #p_Left_2 .value_tunnelInfo    div:nth-child(3),
  #p_Left_2 .value_keyDefectInfo div:nth-child(3) { position: absolute; right: 0; top: 0; width: 120px; font-weight: bold; text-align: right; }
  #p_Left_2 .value_defectInfo div span,
  #p_Left_2 .value_tunnelInfo div span,
  #p_Left_2 .value_keyDefectInfo div span{ font-family: 'Fjalla One', sans-serif; font-size: 32px; margin-right: 5px; }

</style>

<template>
  <div id="dv-left-program">
    <div>
      <dv-img class="bg" src="./imgs/img/left/left.json" type="svg" loop="true"></dv-img>
      <div id="program_caption" v-text="ProjectSimpleInfo.Name"></div>
    </div>

    <div id="p_Left_2" class="page">
      <dv-img class="bg01" src="imgs/p3/f189986e-908b-43ea-9c2f-8e88d5965624.png"></dv-img>
      <div class="videos">
        <div class="videoBox">
          <dv-img id="video-10-bg" class="video-0-bg" src="imgs/p10/76126803-86ed-4e94-9f3a-589f5cb608e8.png"></dv-img>
          <video id="video-10" class="video-0" autoplay="autoplay" loop="loop" muted="muted" :src="currentProgramVideo" @click="scaleVideo"></video>
          视频已放大，点击视频恢复
        </div>
        <dv-img v-for="(video, index) in VideoList.slice(0, 3)" :key="index" :class="'smallpic video-' + (index + 1) + ' videoImg'" :src="ImgBaseUrl + video.CoverLink" :data-src="video.OssUrl" @click="toggleProgramVideo"></dv-img>
      </div>
      <div class="text address">项目地点：<span v-text="ProjectSimpleInfo.Address"></span></div>
      <div class="text department">项目部门：<span v-text="ProjectSimpleInfo.SiteName"></span></div>
      <div class="text explains" v-text="ProjectSimpleInfo.Describe"></div>
      
      <dv-img class="bg02" src="imgs/p3/7de0ba79-5edb-4ff7-ba79-e26c1f26f421.png"></dv-img>
      <div class="text lable_tnlTotalLength">规划隧道总长（M）</div>
      <div class="text lable_tnlBetonLength">衬砌总长（M）</div>
      <div class="text lable_tnlCheckLength">已检测总长（M）</div>
      <div class="text lable_tnlCheckRate">已检覆盖率（%）</div>
      <div class="text value_tnlTotalLength"><span v-text="DesignLength"></span></div>
      <div class="text value_tnlBetonLength"><span v-text="CrackLength"></span></div>
      <div class="text value_tnlCheckLength"><span v-text="TestLength"></span></div>
      <div class="text value_tnlCheckRate"><span v-text="TestRate"></span></div>
      
      <dv-img class="bg03" src="imgs/p101/03-s-bg.png"></dv-img>
      <div class="text lable_defectRepairRate">缺陷修复率</div>
      <div class="text lable_keyDefectRepairRate">重大缺陷修复率</div>
      <div class="text value_defectRepairRate"><span v-text="TotalFaultFixRate + '%'"></span></div>
      <div class="text value_keyDefectRepairRate"><span v-text="TotalFaultBadFixRate + '%'"></span></div>
      
      <!-- <dv-img class="bg04" src="imgs/p3/47343221-f70a-4718-979b-f126cd24d230.png"></dv-img> -->
      <dv-img class="bg04-1" src="imgs/p101/03-s2-bg.png"></dv-img>
      <dv-img class="bg04-2" src="imgs/p101/03-s2-bg.png"></dv-img>
      <dv-img class="bg04-3" src="imgs/p101/03-s2-bg.png"></dv-img>
      <dv-img class="bg04-4" src="imgs/p101/03-s2-bg.png"></dv-img>
      <dv-img class="bg04-5" src="imgs/p101/03-s2-bg.png" @click="GetSevens"></dv-img>
      <dv-img class="bg04-6" src="imgs/p101/03-s2-bg.png" @click="GetMonths"></dv-img>
      <div class="text lable_thickCount">厚度不足（处）/占比</div>
      <div class="text lable_otherCount">空洞、不密实（处）/占比</div>
      <div class="text lable_rebarCount">钢筋缺失（处）/占比</div>
      <div class="text lable_betonCount">防裂钢筋网（处）/占比</div>
      <div class="text lable_betonCount2">7天超时确认（处）/占比</div>
      <div class="text lable_betonCount3">30天超时确认（处）/占比</div>
      <div class="text value_thickCount"><span v-text="FaultCount1"></span></div>
      <div class="text value_otherCount"><span v-text="FaultCount4"></span></div>
      <div class="text value_rebarCount"><span v-text="FaultCount2"></span></div>
      <div class="text value_betonCount"><span v-text="FaultCount3"></span></div>
      <div class="text value_betonCount2"><span v-text="FaultCount5"></span></div>
      <div class="text value_betonCount3"><span v-text="FaultCount6"></span></div>
      <dv-img class="bg05-1" src="imgs/p101/03-yuan-1.png"></dv-img>
      <dv-img class="bg05-2" src="imgs/p101/03-yuan-2.png"></dv-img>
      <dv-img class="bg05-3" src="imgs/p101/03-yuan-3.png"></dv-img>
      <dv-img class="bg05-4" src="imgs/p101/03-yuan-4.png"></dv-img>
      <dv-img class="bg05-5" src="imgs/p101/03-yuan-5.png"></dv-img>
      <dv-img class="bg05-6" src="imgs/p101/03-yuan-6.png"></dv-img>
      <div class="text rate_thickCount"><span v-text="(FaultCount1 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_otherCount"><span v-text="(FaultCount4 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_rebarCount"><span v-text="(FaultCount2 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_betonCount"><span v-text="(FaultCount3 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_betonCount2"><span v-text="(FaultCount5 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      <div class="text rate_betonCount3"><span v-text="(FaultCount6 / _TotalFaultCount * 100).toFixed(0) + '%'"></span></div>
      
      <dv-img class="bg06" src="imgs/p3/c71f4383-bfe3-45c7-9488-2f7583f62f31.png"></dv-img>
      <dv-img class="bg07" src="imgs/p3/c71f4383-bfe3-45c7-9488-2f7583f62f31.png"></dv-img>
      <dv-img class="bg08" src="imgs/p3/c71f4383-bfe3-45c7-9488-2f7583f62f31.png"></dv-img>
      <div class="text lable_defectInfo">缺陷总数 / 缺陷率</div>
      <div class="text lable_keyDefectInfo">重大缺陷 / 重大缺陷率</div>
      <div class="text lable_tunnelInfo">缺陷修复 / 重大缺陷修复</div>
      <div class="text value_defectInfo">
        <div><span v-text="TotalFaultCount"></span>处</div>
        <i>/</i>
        <div><span v-text="TotalFaultRate"></span>%</div>
      </div>
      <div class="text value_keyDefectInfo">
        <div><span v-text="TotalFaultBadCount"></span>处</div>
        <i>/</i>
        <div><span v-text="TotalFaultBadRate"></span>%</div>
      </div>
      <div class="text value_tunnelInfo">
        <div><span v-text="TotalFaultFixCount"></span>处</div>
        <i>/</i>
        <div><span v-text="TotalFaultBadFixCount"></span>处</div>
      </div>
    </div>

  </div>
</template>

<script>
import * as $ from 'jquery';
import dataV from '../../bin/dataV';
import dvImg from "./dv-img.vue";

export default {
  data() {
    return {
      Id: 'LeftProgramComponent',

      AreaName: dataV.Config.AreaName,
      AreaDescription: dataV.Config.AreaDescription,
      ImgBaseUrl: dataV.Config.baseUrl.img,
      currentProgramVideo: '',

      CrackLength: 0,
      CrackRate: 0,
      DesignLength: 0,
      FaultCount1: 0,
      FaultCount2: 0,
      FaultCount3: 0,
      FaultCount4: 0,
      FaultCount5: 0,
      FaultCount6: 0,
      FaultRate1: 0,
      FaultRate2: 0,
      FaultRate3: 0,
      FaultRate4: 0,
      FaultRate5: 0,
      FaultRate6: 0,
      FaultTrend1: [],
      FaultTrend2: [],
      FaultTrend3: [],
      FaultTrend4: [],
      ProjectSimpleInfo: {Name: "", SiteName: "", Address: "", Describe: ""},
      TestLength: 0,
      TestRate: 0,
      TotalFaultBadCount: 0,
      TotalFaultBadFixRate: 0,
      TotalFaultBadRate: 0,
      TotalFaultCount: 0,
      TotalFaultFixRate: 0,
      TotalFaultRate: 0,
      TunnelCompareFault: [],
      TunnelCompareFix: [],
      TunnelCount: 0,
      VideoList: [],
      TotalFaultFixCount: 0,
      TotalFaultBadFixCount: 0,
      
      WindowStatus: {
        isProgramVideoOpen: false,
      },

    };
  },
  props: [],
  computed: {
    _TotalFaultCount(){
      let t = this.FaultCount1 + this.FaultCount2 + this.FaultCount3 + this.FaultCount4
      if(t == 0) t = 1
      return t
    }
  },
  methods: {

      GetSevens(){
				this.$api.request({ Vue: this, apiName: 'checkOver', query: { tempId: dataV.Page.CurrentId, datatype: 1 } }).then(response => {
					if (response.state === 'success') {
						const json = JSON.parse(response.data)
						window.CenterWindowsComponent.set('sevens', json)
					} else {
						alert(response.message)
					}
				})
			},

      GetMonths(){
				this.$api.request({ Vue: this, apiName: 'fixOver', query: { tempId: dataV.Page.CurrentId, datatype: 1 } }).then(response => {
					if (response.state === 'success') {
						const json = JSON.parse(response.data)
						window.CenterWindowsComponent.set('months', json)
					} else {
						alert(response.message)
					}
				})
			},

        
    toggleProgramVideo(){
      var $v = $(event.target);
      this.currentProgramVideo = $v.data('src');
    },

    // 手动方式，设置当前模块的语音声控指令，已达到手动控制的效果
    scaleVideo(){
      if($(event.target).width() < 400){
        this.enlargeVideo();
      }else{
        this.narrowVideo();
      }
    },

    // 放大视频
    enlargeVideo(){
      dataV.ModelWindows.close('项目视频');
      this.WindowStatus.isProgramVideoOpen = true;

      var el = document.getElementById('video-10');
      $('#video-10-bg').css({'z-index': dataV.Config.zIndex.video - 1}).animate({
        left: '1247px',
        top: '-67px',
        width: '1148px',
        height: '774px',
        'z-index': dataV.Config.zIndex.video - 1
      }, 500);
      $(el).css({'z-index': dataV.Config.zIndex.video}).animate({
        left: '1253px',
        top: '-1px',
        width: '1136px',
        height: '639px',
        'z-index': dataV.Config.zIndex.video
      }, 500, function(){
        el.muted = false;
        el.loop = false;
        el.currentTime = 0;
      });
    },

    // 缩小视频
    narrowVideo(){
      // 缩小全国区域视频
      if(this.WindowStatus.isCompanyVideoOpen){
        this.WindowStatus.isCompanyVideoOpen = false;
        var el = document.getElementById('video-0');
        el.muted = true;
        el.loop = true;
        $('#video-0-bg').animate({
          left: '156px',
          top: '88px',
          width: 0,
          height: 0,
        }, 500);
        $(el).animate({
          left: 0,
          top: 0,
          width: '313px',
          height: '176px'
        }, 500);
      }
        
      // 缩小项目部视频
      if(this.WindowStatus.isProgramVideoOpen){
        this.WindowStatus.isProgramVideoOpen = false;
        var el2 = document.getElementById('video-10');
        el2.muted = true;
        el2.loop = true;
        $('#video-10-bg').animate({
          left: '156px',
          top: '88px',
          width: 0,
          height: 0,
        }, 500);
        $(el2).animate({
          left: 0,
          top: 0,
          width: '280px',
          height: '158px'
        }, 500);
      }
    },
    
    // 切换视频
    toggleVideo () {
      var $v = $(event.target);
      this.currentVideo = $v.data('src');
    },

  },
  created() {
			window[this.Id] =  this;
  },
  mounted() { 
			// 注册弹出窗口
			dataV.ModelWindows.regist('项目视频', this.WindowStatus, 'isProgramVideoOpen', true, this.narrowVideo);
  },
  watch: {
    ProjectSimpleInfo() {
      if(!window.ApiData[dataV.Page.CurrentId]) return;
      this.currentProgramVideo = window.ApiData[dataV.Page.CurrentId].VideoList.length > 0 ? window.ApiData[dataV.Page.CurrentId].VideoList[0].OssUrl : ''
    },
  },
  components: {
    "dv-img": dvImg,
  },
};
</script>
